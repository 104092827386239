import * as types from './types';

/**
 * JOBS LIST
 */
export const requestJobsList = () => ({
  type: types.GET_JOBS_LIST_REQUEST,
  payload: null,
});

export const receiveJobsList = (data) => ({
  type: types.GET_JOBS_LIST_SUCCESS,
  payload: data,
});

export const receiveJobsListError = (errorCode) => ({
  type: types.GET_JOBS_LIST_FAILURE,
  payload: errorCode,
});

export const changeSort = (sortStr) => ({
  type: types.CHANGE_SORT,
  payload: sortStr,
});

export const changeCurrentPage = (page) => ({
  type: types.CHANGE_CURRENT_PAGE,
  payload: page,
});
