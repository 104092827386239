import React from 'react';
import { DialogActions, DialogContent } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import isEmpty from 'lodash/isEmpty';
import {
  StyledDialog,
  StyledDialogButton,
  StyledDialogContextText,
  StyledDialogTitle,
} from './style';
import { DEFAULT_CONFIRMATION } from '../../state/ducks/dialog/constants';
import { setConfirmation } from '../../state/ducks/dialog/actions';

function ConfirmationDialog() {
  const confirmation = useSelector((state) => state.dialog.confirmation);
  if (isEmpty(confirmation)) {
    return null;
  }
  const {
    dialog: { title, text: dialogText },
    button: { onClick, text: buttonText },
  } = confirmation;
  const dispatch = useDispatch();
  const onClose = () => {
    dispatch(setConfirmation(DEFAULT_CONFIRMATION));
  };
  return (
    <StyledDialog
      open={!isEmpty(confirmation)}
      onClose={() => {
        onClose();
      }}
    >
      <StyledDialogTitle id="confirmation-dialog-title">
        {title}
      </StyledDialogTitle>
      <DialogContent>
        <StyledDialogContextText id="confirmation-dialog-text">
          {dialogText}
        </StyledDialogContextText>
      </DialogContent>
      <DialogActions>
        <StyledDialogButton
          variant="text"
          onClick={() => {
            onClose();
          }}
        >
          Cancel
        </StyledDialogButton>
        <StyledDialogButton
          id="confirmation-dialog-submit-button"
          variant="text"
          onClick={() => {
            onClick();
            onClose();
          }}
        >
          {buttonText}
        </StyledDialogButton>
      </DialogActions>
    </StyledDialog>
  );
}

export default ConfirmationDialog;
