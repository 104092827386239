import styled from '@emotion/styled/macro';
import { Pagination, Paper, Typography } from '@mui/material';
import { ReactComponent as Empty } from '../../assets/empty-states/empty.svg';
import {
  primaryFont,
  primaryFontLineHeight,
  primaryFontSize,
  primaryFontWeight,
  secondaryFont,
  secondaryFontLineHeight,
  secondaryFontSize,
  secondaryFontWeight,
} from '../../styles/theme/fonts';
import {
  mercury,
  primaryText,
  secondaryColor,
  secondaryColorHover,
  white,
} from '../../styles/theme/colors';
import { tabletCutoff } from '../../styles/theme';

export const IntermediateHeaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 8px 16px;
  font-family: ${primaryFont};
  font-size: ${secondaryFontSize};
  font-weight: ${primaryFontWeight};
  line-height: ${secondaryFontLineHeight};
  border-top: 1px solid #d8dde0;
  border-bottom: 1px solid #d8dde0;
`;

export const FirstIntermediateHeaderWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
`;

export const FirstH4Header = styled(Typography)`
  display: flex;
  justify-content: flex-start;
  width: 50%;
`;

export const SuggestAssociateName = styled(FirstH4Header)`
  font-size: ${primaryFontSize} !important;
  line-height: ${secondaryFontLineHeight} !important;
  font-weight: ${primaryFontWeight} !important;
  letter-spacing: 0.15px !important;
  color: #243745 !important;
`;

export const LeftAssociateId = styled(Typography)`
  display: flex;
  justify-content: flex-start;
  width: 50%;
  padding-bottom: 8px;
  color: #65727b !important;
`;

export const SecondH4Header = styled(Typography)`
  display: flex;
  justify-content: flex-end;
  width: 50%;
`;

export const FullWidthSpan = styled.span`
  display: flex;
  width: 100%;
`;

export const DisplayAssociateWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 16px 16px;
  background-color: white;
  border-top: outset 1px;
  font-family: ${secondaryFont};
  font-size: ${secondaryFontSize};
  font-weight: ${secondaryFontWeight};
  line-height: ${secondaryFontLineHeight};
`;

export const RowWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
`;

export const LastRowWrapper = styled(RowWrapper)`
  color: #243745;
`;

export const AssignOrViewAssociateWrapper = styled.div`
  display: ${(props) => props.display};
  position: absolute;
  top: 0;
  height: 100%;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  background: rgba(0, 0, 0, 0.4);
  width: 100%;

  .space-between-buttons {
    margin-left: 15px;
  }
`;

// HEADER
export const HeaderWrapper = styled.header`
  position: relative;
  color: ${primaryText};
  background-color: ${white};
  overflow: hidden;
  padding: 16px 16px;

  a:hover {
    text-decoration: underline;
  }

  &::after {
    content: ' ';
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 70px;
    background-image: linear-gradient(
      rgba(255, 255, 255, 0),
      rgba(255, 255, 255, 1)
    );
  }

  *:not(div) {
    /* In order for the blurred map overlay to not go over everything else */
    z-index: 10;
  }

  .close-drawer {
    position: absolute;
    top: 15px;
    right: 15px;
    font-size: 24px;
    background-color: white;
    padding: 10px;
    border-radius: 50%;
    line-height: 0;
    cursor: pointer;
    z-index: 20;
    transition: all 0.1s ease-in-out;

    i {
      color: ${primaryText};
    }

    &:hover {
      box-shadow: 0 0 25px rgba(0, 0, 0, 0.15);
    }
  }

  .content-wrapper {
    display: inline-block;

    h1 {
      font-size: 1.4rem;
      font-weight: 200;
      margin: 0;

      strong {
        font-weight: 600;
      }
    }

    h2 {
      font-size: 1rem;
      margin: 0;
    }

    @media only screen and (max-width: ${tabletCutoff}) {
      h1 {
        font-size: 1.1em;
      }

      h2 {
        font-size: 1em;
      }

      .color-status-bar--text {
        font-size: 0.75rem;
      }
    }
  }
`;

export const StyledAssociates = styled.div`
  padding-bottom: 64px;
`;

export const StyledPagination = styled(Pagination)`
  display: flex;
  justify-content: flex-end;
  width: 70%;
  padding-right: 16px;
  .Mui-selected {
    color: ${white};
    background-color: ${secondaryColor} !important;
  }
  .MuiPaginationItem-root:hover {
    background-color: ${secondaryColorHover};
  }
`;

export const AssociateWrapper = styled.div`
  position: relative;
`;

export const StyledCountSuggestWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const StyledAdvancedSearchWrapper = styled.button`
  display: flex;
  padding: 0px;
  margin-top: 8px;
  align-items: center;
  color: ${secondaryColor};
`;

export const StyledAdvancedSearchH2 = styled.span`
  font-size: ${primaryFontSize};
  font-weight: ${primaryFontWeight};
  line-height: ${secondaryFontLineHeight};
  :hover {
    text-decoration: underline;
  }
`;

export const StyledOuter = styled.section`
  font-size: 1rem;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  h3 {
    cursor: pointer;
    user-select: none;
    position: relative;

    a {
      display: block;
      border-top: none;
      padding: 15px 15px 15px 0;
      color: ${primaryText};
      text-decoration: none;

      i {
        float: right;
        font-size: 1.5em;
        transform: rotateX(0deg);
        transition: transform 0.1s ease-out;
      }
    }
  }

  &.is-expanded {
    flex: 1;
    min-height: 200px;

    .expand-icon {
      transform: rotateX(180deg);
    }
  }
`;

export const ErrorCloseButton = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  padding: 26px;
`;

export const MatchedAssociatesHeader = styled(Typography)`
  color: #243745;
`;

export const ToggleLabel = styled.span`
  font-size: ${secondaryFontSize};
`;

export const SecondaryFontBolder = styled.span`
  font-size: ${secondaryFontSize};
  font-weight: ${primaryFontWeight};
`;

export const NotFoundItem = styled(Paper)`
  box-shadow: none !important;
  width: 275px;
  margin: 0 auto;
  text-align: center;
  background-color: rgba(255, 255, 255, 0) !important;
`;

export const NotFoundItemPrimary = styled(NotFoundItem)`
  font-family: ${primaryFont};
  font-weight: ${primaryFontWeight};
  line-height: ${primaryFontLineHeight};
  color: #243745 !important;
`;

export const NotFoundItemSecondary = styled(NotFoundItem)`
  font-family: ${secondaryFont};
  font-weight: ${secondaryFontWeight};
  line-height: ${secondaryFontLineHeight};
  font-size: ${secondaryFontSize} !important;
  color: #6a767f !important;
  padding-top: 4px;
`;

export const StyledEmpty = styled(Empty)`
  margin: 16px;
`;

export const AssignmentIdAndHomeSystem = styled.div`
  font-family: ${secondaryFont};
  font-size: 12px;
  font-weight: ${secondaryFontWeight};
  line-height: 14px;
  letter-spacing: 0;
  text-align: left;
  color: #65727b;
`;

export const CurrentlyAssignedWrapper = styled.div`
  background-color: white;
  border-top: outset 1px;
  width: 100%;
`;

export const CurrentlyAssignedTo = styled.div`
  font-family: ${secondaryFont};
  font-size: 12px;
  font-weight: ${secondaryFontWeight};
  line-height: 15px;
  letter-spacing: 0;
  text-align: left;
  width: 100%;
  color: #65727b !important;
`;

export const AssociateId = styled(CurrentlyAssignedTo)`
  line-height: ${secondaryFontLineHeight};
  font-size: ${secondaryFontSize};
`;

export const AssociateName = styled.div`
  width: 100%;
  font-family: ${secondaryFont};
  font-size: ${primaryFontSize};
  font-weight: ${primaryFontWeight};
  line-height: ${secondaryFontLineHeight};
  color: #243745;
`;

export const CurrentlyAssignedViewButton = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 50%;
  padding: 16px;
`;

export const NameAndIdWrapper = styled.div`
  width: 50%;
  padding: 16px;
`;

export const Footer = styled.div`
  width: 550px;
  display: flex;
  align-items: center;
  border-top: 1px solid ${mercury};
  background-color: ${white};
  bottom: 0;
  position: absolute;
  padding: 16px;
`;

export const StyledCount = styled.div`
  display: flex;
  justify-content: flex-start;
  width: 30%;
`;
