/**
 * @name createAuthRefreshInterceptor
 * @description
 * This is where we handle the refreshing of JWT access tokens
 */

import { VIEWS, URL } from '../../config';
import auth from '../../../services/auth';

let isAlreadyFetchingAccessToken = false;
let requestQueue = [];

const sendQueuedRequests = () => {
  requestQueue.map((cb) => cb());
  requestQueue = [];
};

export default function createAuthRefreshInterceptor(axiosInstance) {
  return axiosInstance.interceptors.response.use(
    // For a 200 response, we just pass the response along
    (response) => response,

    // If we get an error back, we parse the information and
    // handle the fetching of a new access token if needed
    (error) => {
      const { config, response } = error;
      if (response) {
        const { status, request } = response;
        const originalConfig = config;

        /**
         * If we get a 401 or a 403 back while trying to log in, it means
         * we don't have a valid session in localStorage but cookies saved
         * by the API are still present and we have no way of knowing that
         * since they're HttpOnly. So when this happens we need to send
         * the user to the logout page to clear those cookies and then try again
         *
         * TODO: Remove this when the API has fixed this on their end
         */
        if (
          [401, 403].includes(status) &&
          request.responseURL.includes(URL.login)
        ) {
          return Promise.reject(error);
        }

        // If the error status is a 401, we start collecting any
        // additional requests into a queue while we refresh
        // the access token
        if (status === 401) {
          if (request.responseURL.includes(URL.tokenRefresh)) {
            // If the 401 is coming back from our token refresh endpoint, that
            // means the refresh token has expired so we log the user out
            requestQueue = [];
            window.location.replace(VIEWS.logout);
            return Promise.reject(error);
          }

          if (!isAlreadyFetchingAccessToken) {
            isAlreadyFetchingAccessToken = true;

            auth
              .refreshAccessToken()
              .then(() => {
                sendQueuedRequests();
              })
              .catch(() => {
                window.location.replace(VIEWS.logout);
              })
              .finally(() => {
                isAlreadyFetchingAccessToken = false;
              });
          }

          const requestSubscribers = new Promise((resolve) => {
            requestQueue.push(() => {
              resolve(axiosInstance(originalConfig));
            });
          });

          return requestSubscribers;
        }
        return Promise.reject(error);
      }
      return Promise.resolve();
    }
  );
}
