import { combineReducers } from 'redux';

import abortReducer from './abort';
import accessReducer from './access';
import coreReducer from './core';
import dialogReducer from './dialog';
import filtersReducer from './filters';
import jobsReducer from './jobs';
import suggestAssociateReducer from './suggestAssociate';
import viewAssociateReducer from './viewAssociate';
import toastReducer from './toast';

const rootReducer = combineReducers({
  abort: abortReducer,
  access: accessReducer,
  core: coreReducer,
  dialog: dialogReducer,
  filters: filtersReducer,
  jobs: jobsReducer,
  suggestAssociate: suggestAssociateReducer,
  toast: toastReducer,
  viewAssociate: viewAssociateReducer,
});

export default rootReducer;
