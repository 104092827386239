import { IMGIX_URL } from '../../core/config';

/**
 * Shorten number to thousands, millions, billions, etc.
 * http://en.wikipedia.org/wiki/Metric_prefix
 *
 * @param {number} num Number to shorten.
 * @param {number} [digits=0] The number of digits to appear after the decimal point.
 * @returns {string|number}
 *
 * @example
 * // returns '12.5k'
 * shortenLargeNumber(12543, 1)
 *
 * @example
 * // returns '-13k'
 * shortenLargeNumber(-12567)
 *
 * @example
 * // returns '51M'
 * shortenLargeNumber(51000000)
 *
 * @example
 * // returns 651
 * shortenLargeNumber(651)
 *
 * @example
 * // returns 0.12345
 * shortenLargeNumber(0.12345)
 */
export function abbreviateNumber(num, digits = 0) {
  const units = ['k', 'M', 'G', 'T', 'P', 'E', 'Z', 'Y'];
  let decimal;

  for (let i = units.length - 1; i >= 0; i -= 1) {
    decimal = 1000 ** (i + 1);

    if (num <= -decimal || num >= decimal) {
      return +(num / decimal).toFixed(digits) + units[i];
    }
  }

  return num;
}

/**
 * Given minutes, returns hours and minutes
 *
 * @param {number} mins Number of minutes.
 * @returns {string}
 *
 * @example
 * // returns '1 hour and 22 minutes'
 * convertMinsToHrsMins(82)
 */
export function convertMinsToHrsMins(
  mins,
  roundToHour = false,
  abbreviateSuffixOnLongerStrings = false
) {
  const h = Math.floor(mins / 60);
  const m = mins % 60;

  let hPhrase = h === 1 ? ' hour' : ' hours';
  let mPhrase = m === 1 ? ' minute' : ' minutes';

  if (h <= 0) {
    return `${m}${mPhrase}`;
  }

  if (h >= 1 && m <= 0) {
    return `${h}${hPhrase}`;
  }

  if (h >= 1 && roundToHour) {
    const roundedH = Math.round(mins / 60) % 24;
    return `~${roundedH}${hPhrase}`;
  }

  if (abbreviateSuffixOnLongerStrings) {
    hPhrase = 'h';
    mPhrase = 'm';
  }

  return `${h}${hPhrase}${
    abbreviateSuffixOnLongerStrings ? ' ' : ' and '
  }${m}${mPhrase}`;
}

/**
 * @function convertPhotoUrlToImgxUrl
 * @param {string} url The url to a photo
 * @description
 * We tend to get AWS url's for photos from the API, but in order to manipulate
 * their size before displaying to the user we need to convert them to imgX urls
 */
export function convertPhotoUrlToImgxUrl(url) {
  const imageId = url.split('/').pop();
  return `${IMGIX_URL}${imageId}`;
}

export function scrollIntoView(expanded, elementId, timeoutMs = 300) {
  const scrollToDiv = document.getElementById(elementId);
  if (expanded && scrollToDiv) {
    setTimeout(() => {
      scrollToDiv.scrollIntoView({
        behavior: 'smooth',
        block: 'end',
      });
    }, timeoutMs);
  }
}

export const formatErrorFromSubsystem = (
  message,
  error,
  externalIdentifier,
  homeSystem
) => `${externalIdentifier}-${homeSystem} - ${message}: ${error}`;

export function capitalizeWordsInAllCaps(str) {
  const lowercaseStr = str.toLowerCase();
  const wordsArray = lowercaseStr.split(' ');
  const capitalizedWords = wordsArray.map(
    (word) => word.charAt(0).toUpperCase() + word.slice(1)
  );
  return capitalizedWords.join(' ');
}

export function concatenateAndPrepend(prefix, sortableFields, staticFields) {
  if (sortableFields.length === 0) {
    return staticFields.join(',');
  }
  const modifiedSortableFields = sortableFields.map(
    (item) => `${prefix}${item}`
  );
  const concatenatedArray = [...modifiedSortableFields, ...staticFields];
  return concatenatedArray.join(',');
}

export function capitalizeFirstLetter(str) {
  return str
    .split('_')
    .map((item) => {
      const [firstLetter, ...rest] = item.split('');
      return [firstLetter.toUpperCase(), ...rest].join('');
    })
    .join(' ');
}

export function stringify(object) {
  return object.reduce(
    (accumulator, [key, value], index) =>
      `${accumulator}${key}=${value}${object.length - 1 !== index ? '&' : ''}`,
    ''
  );
}
