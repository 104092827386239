import React from 'react';
import { useSelector } from 'react-redux';

import { ReactComponent as ErrorIcon } from '../../assets/empty-states/503-error.svg';
import Header from '../Header';
import { ErrorWrapper } from '../../styles/components';

function PlannedMaintenanceHandler({ children }) {
  const isMaintenanceModeActive = useSelector(
    (state) => state.core.isMaintenanceModeActive
  );

  if (isMaintenanceModeActive) {
    return (
      <>
        <Header />
        <ErrorWrapper>
          <ErrorIcon />
          <h1>Temporarily down for maintenance</h1>
          <h2>
            We apologize for any inconvenience. We should be back up soon.
            Please check again in a little while.
          </h2>
        </ErrorWrapper>
      </>
    );
  }

  return children;
}

export default PlannedMaintenanceHandler;
