import * as React from 'react';
import { Box, Toolbar } from '@mui/material';
import styled from '@emotion/styled/macro';
import AlertDialog from '../Dialog/AlertDialog';
import ConfirmationDialog from '../Dialog/ConfirmationDialog';
import Menu from '../../containers/Menu';
import Header from '../../containers/Header';
import { SHOULD_NOT_SHOW_BANNER } from '../../containers/Banner/constants';
import Toast from '../Toast';

const StyledBox = styled(Box)`
  background-color: #f5f7f9;
  height: 100%;
`;

const Content = styled.div`
  flex: 1;
  overflow-y: auto;
`;

function PageLayout({ children, hasFilters }) {
  return (
    <>
      <AlertDialog />
      <ConfirmationDialog />
      <Toast />
      <Menu />
      <Header hasFilters={hasFilters} />
      <StyledBox>
        <Toolbar
          sx={{
            height: SHOULD_NOT_SHOW_BANNER ? 'unset' : 83,
          }}
        />
        <Content>{children}</Content>
      </StyledBox>
    </>
  );
}

PageLayout.defaultProps = {
  hasFilters: false,
};

export default PageLayout;
