/**
 * @name createCsrfTokenInterceptor
 * @description
 * For POST, DELETE and PUT requests, we want to include the CSRF
 * token that we get back from the API in the
 */

import Cookies from 'js-cookie';

import { CSRF_TOKEN_COOKIE_KEY } from '../../config';

export default function createCsrfTokenInterceptor(axiosInstance) {
  return axiosInstance.interceptors.request.use((config) => {
    const mutatedConfig = Object.assign({}, config);
    const { method } = mutatedConfig;

    if (['post', 'delete', 'put'].includes(method)) {
      const csrfToken = Cookies.get(CSRF_TOKEN_COOKIE_KEY);

      if (csrfToken) {
        mutatedConfig.headers['X-CSRFToken'] = csrfToken;
      }
    }

    return mutatedConfig;
  });
}
