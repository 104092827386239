import * as React from 'react';

import { MenuItem, MenuList, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { VIEWS } from 'core/config';
import { NavLink } from 'react-router-dom';

function Tabs() {
  const theme = useTheme();

  return (
    <MenuList
      css={{ display: 'flex', marginLeft: '30px', padding: 0 }}
      id="showTabs"
      key="menu-list"
    >
      <MenuItem
        as={NavLink}
        key="Staffing Work"
        to={VIEWS.home}
        css={{
          marginLeft: '30px',
          padding: 0,
        }}
      >
        <Typography
          css={{
            '&:hover': {
              color: theme.palette.tertiary.main,
            },
          }}
          variant="subtitle1"
        >
          Staffing Work
        </Typography>
      </MenuItem>
      <MenuItem
        as={NavLink}
        key="Associates"
        to={VIEWS.associates}
        css={{
          marginLeft: '30px',
          padding: 0,
        }}
      >
        <Typography
          css={{
            '&:hover': {
              color: theme.palette.tertiary.main,
            },
          }}
          variant="subtitle1"
        >
          Associates
        </Typography>
      </MenuItem>
    </MenuList>
  );
}
export default Tabs;
