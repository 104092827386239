const camelToSnake = (value) => value.replace(/([A-Z])/g, '_$1').toLowerCase();

export const snakeToCamel = (value) =>
  value
    .toLowerCase()
    .replace(/([-_][a-z])/g, (group) =>
      group.toUpperCase().replace('-', '').replace('_', '')
    );

export const objectKeysCamelToSnake = (object) =>
  Object.keys(object).reduce(
    (result, key) => ({
      ...result,
      [camelToSnake(key)]: object[key],
    }),
    {}
  );
