/* eslint-disable react/no-array-index-key */
import React from 'react';
import { Typography } from '@mui/material';
import JobStatusChip from './JobStatusChip';

function JobStatus({
  jobId,
  status,
  statusAttributes = [],
  confirmationStatus,
}) {
  return (
    <>
      <Typography id="job_status" variant="body2" sx={{ paddingBottom: 1 }}>
        {status}
      </Typography>
      {statusAttributes.map((attribute, index) => (
        <JobStatusChip
          jobId={jobId}
          variant="outlined"
          jobDetails={false}
          status={attribute}
          key={`${attribute}-${index}`}
        />
      ))}
      {confirmationStatus && (
        <JobStatusChip
          jobId={jobId}
          variant="outlined"
          status={confirmationStatus}
          jobDetails={false}
        />
      )}
    </>
  );
}

export default JobStatus;
