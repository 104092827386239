import * as types from './types';

export const setShowMainMenu = (isOpen) => ({
  type: types.SET_SHOW_MAIN_MENU,
  payload: isOpen,
});

export const apiReturned503 = () => ({
  type: types.ENABLE_MAINTENANCE_MODE,
  payload: null,
});

export const setFeatureFlags = (featureFlags) => ({
  type: types.SET_FEATURE_FLAG,
  payload: featureFlags,
});
