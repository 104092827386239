import React, { useState } from 'react';

import { Tooltip } from '@mui/material';
import { utcToZonedTime, format } from 'date-fns-tz';
import { HoverableDateTimeDiv, JobDateTimePickerWrapper } from '../style';
import JobDateTimePicker from './JobDateTimePicker';

const disabledText = (isNotEditable, isCompleted) => {
  if (isNotEditable) {
    return 'The Job Source System does not allow Datetime editing';
  }
  if (isCompleted) {
    return 'Assignments in a Completed status cannot be edited';
  }
  return '';
};

function JobDateInfo({
  id,
  status,
  startDate,
  storeTimezone,
  createdJobStartAfter,
  createdJobFinishBefore,
  advantageSourceSystemName,
  externalIdentifier,
  getUpdatedJob,
}) {
  const isCompleted = status === 'Completed';
  const isNotEditable = advantageSourceSystemName !== 'JET';
  const disabled = isNotEditable || isCompleted;
  const [isHovering, setIsHovering] = useState(false);
  const startDateWithTimezone = utcToZonedTime(startDate, storeTimezone);
  const day = format(startDateWithTimezone, 'EEEE', {
    timeZone: storeTimezone,
  });

  return (
    <HoverableDateTimeDiv
      onMouseEnter={() => {
        setIsHovering(true);
      }}
      onMouseLeave={() => {
        setIsHovering(false);
      }}
      className="job-date-wrapper"
    >
      <Tooltip title={disabledText(isNotEditable, isCompleted)}>
        <section className="job-date-wrapper--date">
          {format(startDateWithTimezone, 'M/d/yyyy', {
            timeZone: storeTimezone,
          })}
        </section>
        {day}
      </Tooltip>
      {isHovering && !disabled && (
        <JobDateTimePickerWrapper>
          <JobDateTimePicker
            id={id}
            openTo="day"
            value={startDateWithTimezone}
            minDateTime={utcToZonedTime(createdJobStartAfter, storeTimezone)}
            maxDateTime={utcToZonedTime(createdJobFinishBefore, storeTimezone)}
            externalIdentifier={externalIdentifier}
            advantageSourceSystemName={advantageSourceSystemName}
            getUpdatedJob={getUpdatedJob}
            storeTimezone={storeTimezone}
          />
        </JobDateTimePickerWrapper>
      )}
    </HoverableDateTimeDiv>
  );
}

export default JobDateInfo;
