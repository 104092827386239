/* eslint-disable camelcase */
import React from 'react';
import { useDispatch } from 'react-redux';
import { utcToZonedTime, zonedTimeToUtc } from 'date-fns-tz';
import { updateSchedule } from '../../../services/jobs';
import DateTimePicker from '../../DateTimePicker/DateTimePicker';
import { formatErrorFromSubsystem } from '../../../lib/utils';
import { setConfirmation } from '../../../state/ducks/dialog/actions';
import { setToast } from '../../../state/ducks/toast/actions';

function JobDateTimePicker({
  value,
  maxDateTime,
  minDateTime,
  id,
  openTo,
  advantageSourceSystemName,
  externalIdentifier,
  getUpdatedJob,
  storeTimezone,
}) {
  const dispatch = useDispatch();

  const handleOnAccept = (selectedDate, ignoreWarnings) => {
    const selectedDateRedo = selectedDate;
    updateSchedule(id, selectedDate, ignoreWarnings)
      .then(() => {
        dispatch(setToast({ message: 'Date changes successfully saved' }));
        getUpdatedJob(id);
      })
      .catch(
        ({
          response: {
            data: { error_code, detail },
          },
        }) => {
          if (error_code === 'ValidationWarning') {
            dispatch(
              setConfirmation({
                dialog: {
                  title: 'Warning',
                  text: detail,
                },
                button: {
                  onClick: () => {
                    handleOnAccept(selectedDateRedo, true);
                  },
                  text: 'Continue',
                },
              })
            );
            return;
          }
          dispatch(
            setToast({
              message: formatErrorFromSubsystem(
                'Something went wrong updating the job',
                detail,
                externalIdentifier,
                advantageSourceSystemName
              ),
              error: true,
            })
          );
        }
      );
  };

  return (
    <DateTimePicker
      value={value}
      timezone={storeTimezone}
      minDateTime={minDateTime}
      maxDateTime={maxDateTime}
      onAccept={(selectedDate) => {
        const systemTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        const zonedTime = utcToZonedTime(selectedDate, systemTimezone);
        const timeToUTC = zonedTimeToUtc(zonedTime, storeTimezone);
        handleOnAccept(timeToUTC, false);
      }}
      openTo={openTo}
    />
  );
}

export default JobDateTimePicker;
