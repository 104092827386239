/**
 * @name ToggleSwitch
 * @author Magnus
 * @description
 * An iOS style toggle switch for visually pleasing on/off state
 */

import React from 'react';
import styled from '@emotion/styled/macro';
import { offwhite, secondaryColor, warmGrey } from '../../styles/theme/colors';

const Button = styled.button`
  cursor: pointer;
  display: inline-block;
  font-weight: 400;
  line-height: 1.25;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  margin: 0 0.5rem;
  padding: 0;
  position: relative;
  border: none;
  height: 1.5rem;
  width: 3rem;
  font-size: 0.875rem;
  border-radius: 1.5rem;
  color: ${offwhite};
  background: ${warmGrey};

  .handle {
    position: absolute;
    top: 0.1875rem;
    left: 0.1875rem;
    width: 1.125rem;
    height: 1.125rem;
    border-radius: 1.125rem;
    background: #fff;
    transition: left 0.25s;
  }

  &:before,
  &:after {
    color: #fff;
    letter-spacing: 0.75px;
    left: 0.4125rem;
    width: 2.325rem;
    line-height: 1.5rem;
    font-weight: 600;
    font-size: 0.55rem;
    text-transform: uppercase;
    position: absolute;
    bottom: 0;
    transition: opacity 0.25s;
  }

  &:before {
    content: 'Off';
    text-align: right;
  }

  &:after {
    content: 'On';
    right: -0.5rem;
    text-align: left;
  }

  &.checked {
    background-color: ${secondaryColor};
    transition: background-color 0.25s;

    &:before {
      opacity: 0;
    }

    &:after {
      opacity: 1;
    }

    .handle {
      left: 1.6875rem;
      transition: left 0.25s;
    }
  }

  &:focus {
    outline: none;
    box-shadow: 0 0 0 2px rgba(2, 117, 216, 0.25);
  }
`;

function ToggleSwitch(props) {
  const { checked, id, onToggle } = props;
  const handleToggle = () => {
    if (typeof onToggle === 'function') {
      onToggle(!checked);
    }
  };

  return (
    <Button
      type="button"
      id={id}
      className={checked ? 'checked' : ''}
      onClick={handleToggle}
    >
      <div className="handle" />
    </Button>
  );
}

ToggleSwitch.defaultProps = {
  id: '',
};

export default ToggleSwitch;
