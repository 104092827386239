import React, { useEffect, useState } from 'react';
import { AccordionDetails, AccordionSummary } from '@mui/material';
import axios from 'axios';
import Accordion from '@mui/material/Accordion';
import { getAssociateCertifications } from '../../../services/user';
import {
  AccordionHeader,
  Certification,
  CertificationsInner,
  NoCertifications,
  NoCertificationsDetails,
  RoundedFlexBox,
  StyledBrightnessHigh,
  StyledVerified,
} from '../style';
import ShowError from './ShowError';
import { scrollIntoView } from '../../../lib/utils';

function CertificationsCollapsableHeader({ associate }) {
  const [expanded, setExpanded] = useState(false);
  const [certificationsError, setCertificationsError] = useState(null);
  const [certifications, setCertifications] = useState([]);
  useEffect(() => {
    scrollIntoView(expanded, 'certification-accordion-details', 400);
  }, [expanded, certifications]);

  const getCertifications = () => {
    getAssociateCertifications(associate.id)
      .then((results) => {
        setCertifications(results);
      })
      .catch((err) => {
        if (!axios.isCancel(err)) {
          setCertificationsError(
            err.message || 'There was a problem retrieving the Associate record'
          );
        }

        setCertificationsError(err);
      });
  };

  useEffect(() => {
    if (associate) {
      getCertifications();
    }
  }, [associate]);

  if (certificationsError) {
    return (
      <ShowError
        msg="Oops, something went wrong. Please try again."
        doRetry={() => {
          getCertifications();
        }}
        fullHeight
      />
    );
  }

  return (
    <h3>
      <Accordion
        expanded={expanded}
        square
        onChange={() => {
          setExpanded(!expanded);
        }}
      >
        <AccordionSummary
          expandIcon={<i className="material-icons expand-icon">expand_more</i>}
          aria-controls="panel1b-content"
          id="panel1b-header"
        >
          <div id="certifications">
            <AccordionHeader>Certifications</AccordionHeader>
          </div>
        </AccordionSummary>
        <AccordionDetails id="certification-accordion-details">
          {certifications.length === 0 ? (
            <div id="no-certifications">
              <CertificationsInner>
                <StyledBrightnessHigh fontSize="large" />
              </CertificationsInner>
              <CertificationsInner>
                <NoCertifications>No Certifications</NoCertifications>
              </CertificationsInner>
              <CertificationsInner>
                <NoCertificationsDetails>
                  This associate has no certifications.
                </NoCertificationsDetails>
              </CertificationsInner>
            </div>
          ) : (
            certifications.map((certification, index) => (
              <RoundedFlexBox
                key={certification.id}
                id={`certification-${index}`}
              >
                <StyledVerified fontSize="large" />
                <Certification>{certification.name}</Certification>
              </RoundedFlexBox>
            ))
          )}
        </AccordionDetails>
      </Accordion>
    </h3>
  );
}

export default CertificationsCollapsableHeader;
