import React, { useState } from 'react';

import Chip from '@mui/material/Chip';
import { Stack } from '@mui/material';
import {
  AssignOrViewAssociateWrapper,
  AssociateWrapper,
  DisplayAssociateWrapper,
  FullWidthSpan,
  LastRowWrapper,
  LeftAssociateId,
  RowWrapper,
  SecondH4Header,
  SuggestAssociateName,
} from '../style';
import { determineChipColorAndLabel } from '../utils';

function Associate({
  associate,
  storeLocationId,
  onShowAssociateDetails,
  handleAssignAssociateClick,
}) {
  const [display, setDisplay] = useState('none');
  const isHomeStore = storeLocationId === associate.home_store_location_id;

  return (
    <AssociateWrapper
      className="matched-associate"
      onMouseEnter={() => {
        setDisplay('flex');
      }}
      onMouseLeave={() => {
        setDisplay('none');
      }}
    >
      <DisplayAssociateWrapper>
        <RowWrapper>
          <SuggestAssociateName>{associate.full_name}</SuggestAssociateName>
          <SecondH4Header variant="body2">
            {associate.miles_from_job} miles
          </SecondH4Header>
        </RowWrapper>
        <RowWrapper>
          <LeftAssociateId variant="body2" class="associate_id">
            {associate.external_id}
          </LeftAssociateId>
          <SecondH4Header variant="body2">
            {associate.remaining_hours} hours
          </SecondH4Header>
        </RowWrapper>
        <LastRowWrapper>
          <div>
            <FullWidthSpan>{associate.title}</FullWidthSpan>
            <span>{associate.division_name}</span>
          </div>
        </LastRowWrapper>
        <Stack direction="row" css={{ flexWrap: 'wrap', alignItems: 'center' }}>
          {associate.exceptions.length === 0 ? (
            <Chip
              id="match-chip"
              css={{ marginTop: '8px', marginRight: '8px' }}
              label="Match"
              size="small"
              color="success"
            />
          ) : (
            associate.exceptions.map((exception) => {
              const { color, label } = determineChipColorAndLabel(exception);
              return (
                <Chip
                  id={`${label}-chip`}
                  color={color}
                  key={exception}
                  label={label}
                  size="small"
                  css={{ marginTop: '8px', marginRight: '8px' }}
                />
              );
            })
          )}
          {isHomeStore && (
            <Chip
              id="home-store-chip"
              label="Home Store"
              color="secondary"
              size="small"
              css={{ marginTop: '8px', marginRight: '8px' }}
            />
          )}
        </Stack>
      </DisplayAssociateWrapper>
      <AssignOrViewAssociateWrapper display={display}>
        <button
          type="submit"
          className="applyBtn button is-link matched-associate-view-button"
          disabled={false}
          onClick={() => {
            onShowAssociateDetails({
              hideMatchDetails: false,
              hideAssignButton: false,
              ...associate,
            });
          }}
        >
          View
        </button>
        <button
          type="submit"
          className="applyBtn button is-link space-between-buttons matched-associate-assign-button"
          disabled={false}
          onClick={() => handleAssignAssociateClick(associate.user_id)}
        >
          Assign
        </button>
      </AssignOrViewAssociateWrapper>
    </AssociateWrapper>
  );
}

export default Associate;
