import * as React from 'react';
import { connect } from 'react-redux';

import Drawer from '@mui/material/Drawer';

import ClearAllWrapper from './components/ClearAllWrapper';

import sectionStructure from './utils/sectionStructure';
import { SHOULD_NOT_SHOW_BANNER } from '../Banner/constants';
import DatesSelector from './components/DatesSelector';

export { DEFAULT_SELECTED_FILTERS } from './constants';

class Filters extends React.PureComponent {
  render() {
    const { isOpen } = this.props;

    if (!isOpen) {
      return null;
    }

    return (
      <Drawer
        anchor="right"
        open
        variant="permanent"
        PaperProps={{
          sx: {
            paddingTop: SHOULD_NOT_SHOW_BANNER ? '70px' : '88px',
            width: '400px',
          },
        }}
      >
        <DatesSelector />
        {Object.entries(sectionStructure).map(
          ([key, { Component, ...rest }]) => (
            <Component
              key={`filter-section-${key}`}
              sectionKey={key}
              {...rest}
            />
          )
        )}
        <ClearAllWrapper />
      </Drawer>
    );
  }
}

const mapStateToProps = (state) => ({
  isOpen: state.filters.visibility.isOpen,
});

export default connect(mapStateToProps)(Filters);
