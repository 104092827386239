import styled from '@emotion/styled/macro';
import { Box } from '@mui/material';
import { secondaryColor } from '../../styles/theme/colors';

export const StyledDataGridBox = styled(Box)`
  height: ${(props) => props.height};
  width: ${(props) => props.width};
`;

export const JobDateTimePickerWrapper = styled.div`
  float: left;
  margin-left: 15px;
`;

export const HoverableDiv = styled.div`
  height: 125px;
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const HoverableDateTimeDiv = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
`;

export const AssignAssociateWrapper = styled.div`
  width: 180px;
  display: ${(props) => props.display};
  margin-top: 15px;
`;

export const SearchAssociateWrapper = styled.div`
  height: 40px;
`;

export const SpinnerWrapper = styled.div`
  float: left;
  margin-left: 5px;
  height: 100%;
`;

export const UnassignButton = styled.button`
  height: 100%;
`;

export const Seperator = styled.div`
  border-bottom: 2px solid gainsboro;
  margin-bottom: 2px;
  margin-top: 2px;
`;

export const SuggestAssociateButton = styled.button`
  color: ${secondaryColor};
  font-size: 13px;
  font-weight: 600;
  width: 100%;
`;

export const StatusChipWrapper = styled.div`
  padding-bottom: ${(props) => (props.jobDetails ? '0' : '8px')};
`;
