import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Drawer from '@mui/material/Drawer';
import {
  Avatar,
  Box,
  Divider,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { coreActions } from '../../state/ducks/core';

import auth from '../../services/auth';
import { VIEWS } from '../../core/config';

import { determineInitials } from './utils';

function Menu() {
  const user = auth.getCurrentUser();
  const open = useSelector((state) => state.core.isMainMenuOpen);
  const menuItems = [
    { text: 'Home', href: VIEWS.home },
    { text: 'Logout', href: VIEWS.logout },
  ];
  const dispatch = useDispatch();
  const theme = useTheme();
  return (
    <Drawer
      anchor="left"
      open={open}
      onClose={() => {
        dispatch(coreActions.setShowMainMenu(false));
      }}
      css={{ zIndex: theme.zIndex.drawer + 1 }}
      PaperProps={{
        sx: { width: 280 },
      }}
      id="main-navigational-menu"
    >
      <Box
        css={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          height: '100%',
        }}
      >
        <List>
          {menuItems.map(({ text, href }) => (
            <>
              <ListItem key={text} disablePadding>
                <ListItemButton
                  css={{ width: '280px', height: '64px' }}
                  id={`${text.toLowerCase()}-navigation-link`}
                  href={href}
                >
                  <ListItemText primary={text} />
                </ListItemButton>
              </ListItem>
              <Divider />
            </>
          ))}
        </List>
        {user && (
          <List>
            <ListItem>
              <ListItemIcon>
                <Avatar>{determineInitials(user.fullName)}</Avatar>
              </ListItemIcon>
              <ListItemText
                css={{
                  wordBreak: 'break-all',
                }}
                primary={user.fullName}
                secondary={user.email}
              />
            </ListItem>
          </List>
        )}
      </Box>
    </Drawer>
  );
}

export default Menu;
