import * as types from './types';

export const setAlert = (alert) => ({
  type: types.SET_ALERT,
  payload: alert,
});

export const setConfirmation = (confirmation) => ({
  type: types.SET_CONFIRMATION,
  payload: confirmation,
});
