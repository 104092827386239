/* OPERATIONS = REDUX THUNKS */

import { datadogLogs } from '@datadog/browser-logs';
import HTTP from '../../../core/http';
import { JOBS_LIST_PAGE_SIZE, URL } from '../../../core/config';

import * as actions from './actions';
import * as coreActions from '../core/actions';
import * as toastActions from '../toast/actions';
import { abortOperations } from '../abort';
import { formatFilterCollectionToQueryParams } from '../../../lib/utils/queryParamUtils';
import { stringify } from '../../../lib/utils';

const receivedError = (errorCode) => (dispatch) => {
  dispatch(actions.receiveJobsListError(errorCode));
};

const jobsListRequest = (signal) => (dispatch, getState) => {
  const {
    filters: { selectedFilterItems },
    jobs: {
      list: { page },
      sort: { str: ordering },
    },
  } = getState();

  dispatch(actions.requestJobsList());

  const params = {
    include_jobs_completed: true,
    include_reviews: true,
    include_requirements: true,
    page_size: JOBS_LIST_PAGE_SIZE,
    page: page + 1,
    ordering,
    ...formatFilterCollectionToQueryParams(selectedFilterItems),
  };
  const paramEntries = Object.entries(params);
  const data = stringify(paramEntries);
  const queryParamLength = data.length;
  if (queryParamLength > 4000) {
    dispatch(
      toastActions.setToast({
        message:
          'The filter limit has been reached. Please reduce your filters to resolve the error.',
        error: true,
      })
    );
  }
  return HTTP.get(URL.jobsList, {
    params,
    signal,
  }).catch((err) => err);
};

export const fetchJobs = () => async (dispatch, getState) => {
  const resource = 'fetchJobs';
  await dispatch(abortOperations.abort(resource));
  const {
    abort: { abortControllers },
  } = getState();
  const { signal } = abortControllers[resource];
  try {
    const response = await dispatch(jobsListRequest(signal));

    const statusCode = response.isAxiosError
      ? response.response.status
      : response.status;

    if (statusCode === 401) {
      return;
    }

    if (statusCode === 503) {
      dispatch(coreActions.apiReturned503());
      return;
    }

    if (response.data && response.data.count <= 0) {
      dispatch(receivedError(404));
    } else {
      dispatch(actions.receiveJobsList(response.data));
    }
  } catch (err) {
    if (signal && !signal.aborted) {
      dispatch(receivedError(500));

      if (err.code === 'ECONNABORTED') {
        datadogLogs.logger.warn('Fetching jobs timed out', {}, err);
      } else {
        datadogLogs.logger.error('Error while fetching jobs', {}, err);
      }
    }
  }
};
