import React, { PureComponent } from 'react';

import {
  FirstH4Header,
  FirstIntermediateHeaderWrapper,
  IntermediateHeaderWrapper,
  SecondH4Header,
} from '../style';

class SecondaryHeader extends PureComponent {
  render() {
    return (
      <IntermediateHeaderWrapper>
        <FirstIntermediateHeaderWrapper>
          <FirstH4Header variant="subtitle2">Name</FirstH4Header>
          <SecondH4Header variant="subtitle2">Miles from home</SecondH4Header>
        </FirstIntermediateHeaderWrapper>
        <FirstIntermediateHeaderWrapper>
          <FirstH4Header variant="subtitle2">EID</FirstH4Header>
          <SecondH4Header variant="subtitle2">Remaining hours</SecondH4Header>
        </FirstIntermediateHeaderWrapper>
        <FirstIntermediateHeaderWrapper>
          <FirstH4Header variant="subtitle2">Title, team</FirstH4Header>
          <SecondH4Header variant="subtitle2" />
        </FirstIntermediateHeaderWrapper>
      </IntermediateHeaderWrapper>
    );
  }
}

export default SecondaryHeader;
