import React from 'react';

import JobsList from './components/JobsList';
import Filters, { DEFAULT_SELECTED_FILTERS } from '../Filters';
import PageLayout from '../../components/PageLayout';
import CallToActionBar from '../CallToActionBar';
import AssociateDrawer from '../AssociateDrawer';

function HomeContainer() {
  return (
    <PageLayout hasFilters>
      <CallToActionBar />
      <JobsList />
      <Filters defaultFilters={DEFAULT_SELECTED_FILTERS} />
      <AssociateDrawer />
    </PageLayout>
  );
}

export default HomeContainer;
