import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import isEmpty from 'lodash/isEmpty';
import { Alert, Slide, Snackbar } from '@mui/material';
import { setToast } from '../../state/ducks/toast/actions';
import { DEFAULT_TOAST } from '../../state/ducks/toast/constants';

function SlideTransition(props) {
  return <Slide {...props} direction="left" />;
}

function Toast() {
  const toast = useSelector((state) => state.toast);
  if (isEmpty(toast)) {
    return null;
  }
  const vertical = 'top';
  const horizontal = 'right';
  const dispatch = useDispatch();
  const handleClose = () => {
    dispatch(setToast(DEFAULT_TOAST));
  };

  return (
    <Snackbar
      open
      autoHideDuration={toast.error ? null : 4500}
      anchorOrigin={{ vertical, horizontal }}
      onClose={() => handleClose()}
      TransitionComponent={SlideTransition}
      css={{ width: '400px' }}
    >
      <Alert
        id="toaster"
        severity={toast.error ? 'error' : 'success'}
        variant="filled"
        css={{ width: '100%' }}
      >
        {toast.message}
      </Alert>
    </Snackbar>
  );
}

export default Toast;
