import React, { useEffect } from 'react';
import { Route, Routes, useNavigate } from 'react-router-dom';
import { ErrorBoundary } from 'react-error-boundary';
import { datadogRum } from '@datadog/browser-rum';
import { datadogLogs } from '@datadog/browser-logs';
import { LocalizationProvider } from '@mui/x-date-pickers-pro';

import { LoginCallback, Security } from '@okta/okta-react';
import { OktaAuth, toRelativeUrl } from '@okta/okta-auth-js';
import { useDispatch } from 'react-redux';

import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { ThemeProvider } from '@mui/material/styles';
import HomeContainer from './containers/Home';
import AssociatesContainer from './containers/Associates';
import NotFound404 from './containers/NotFound404';
import PlannedMaintenanceHandler from './containers/PlannedMaintenance503';
import {
  DATADOG_LOGS,
  DATADOG_LOGS_CYPRESS,
  DATADOG_RUM,
  DATADOG_RUM_CYPRESS,
  ENV,
  ENV_E2ETESTS,
  ENV_E2ETESTS_CI,
  ENV_LOCAL,
  OKTA,
  VIEWS,
} from './core/config';
import GAWrapper from './components/GA';

import './styles/App.scss';
import AuthorizationWrapper from './core/router/AuthorizationWrapper';
import Logout from './components/Logout';
import Loading from './core/router/Loading';
import { RequiredAuth } from './core/router/SecureRoute';
import auth from './services/auth';
import { getVersion } from './services/health';
import { theme } from './styles/theme/index';
import FallBack from './containers/FallBack';
import Health from './containers/Health';
import { setIsAuthorized } from './state/ducks/access/actions';

export default function App(props) {
  const dispatch = useDispatch();
  const oneHour = 60 * 60 * 1000;

  useEffect(() => {
    if (![ENV_LOCAL, ENV_E2ETESTS, ENV_E2ETESTS_CI].includes(ENV)) {
      const currentUser = auth.getCurrentUser();
      datadogRum.init(DATADOG_RUM);
      datadogRum.setUser(currentUser);
      datadogRum.startSessionReplayRecording();
      datadogLogs.init(DATADOG_LOGS);
    }

    if ([ENV_E2ETESTS, ENV_E2ETESTS_CI].includes(ENV)) {
      datadogRum.init(DATADOG_RUM_CYPRESS);
      datadogLogs.init(DATADOG_LOGS_CYPRESS);
    }

    const interval = setInterval(async () => {
      await getVersion();
    }, oneHour);

    return () => clearInterval(interval);
  }, []);

  const oktaAuth = new OktaAuth({
    issuer: OKTA[process.env.REACT_APP_ENV_BUILD].issuer,
    clientId: OKTA[process.env.REACT_APP_ENV_BUILD].clientId,
    scopes: ['openid', 'profile', 'email'],
    redirectUri: `${window.location.origin}/login/callback`,
    pkce: true,
  });
  const navigate = useNavigate();
  const restoreOriginalUri = async (_oktaAuth, originalUri) => {
    try {
      const originalRelativeUri = toRelativeUrl(
        originalUri || '/',
        window.location.origin
      );
      await auth.processOktaLogin();
      navigate(originalRelativeUri);
    } catch (e) {
      dispatch(setIsAuthorized(false));
      window.location.replace(VIEWS.home);
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <ErrorBoundary
        FallbackComponent={FallBack}
        onError={(error, info) => {
          datadogLogs.logger.error(
            'ErrorBoundary was invoked',
            {
              info,
            },
            error
          );
        }}
      >
        <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <PlannedMaintenanceHandler>
              <GAWrapper>
                <Routes>
                  <Route
                    path={VIEWS.loginCallback}
                    element={<LoginCallback loadingElement={<Loading />} />}
                  />
                  <Route path={VIEWS.home} element={<RequiredAuth />}>
                    <Route
                      path={VIEWS.home}
                      element={
                        <AuthorizationWrapper
                          component={HomeContainer}
                          {...props}
                        />
                      }
                    />
                  </Route>
                  <Route path={VIEWS.associates} element={<RequiredAuth />}>
                    <Route
                      path={VIEWS.associates}
                      element={
                        <AuthorizationWrapper
                          component={AssociatesContainer}
                          {...props}
                        />
                      }
                    />
                  </Route>
                  <Route path={VIEWS.logout} element={<Logout />} />
                  <Route path={VIEWS.health} element={<Health />} />
                  <Route path={VIEWS.catchAll} element={<RequiredAuth />}>
                    <Route
                      path={VIEWS.catchAll}
                      element={
                        <AuthorizationWrapper
                          component={NotFound404}
                          {...props}
                        />
                      }
                    />
                  </Route>
                </Routes>
              </GAWrapper>
            </PlannedMaintenanceHandler>
          </LocalizationProvider>
        </Security>
      </ErrorBoundary>
    </ThemeProvider>
  );
}
