import { combineReducers } from 'redux';
import * as types from './types';
import { DEFAULT_ALERT, DEFAULT_CONFIRMATION } from './constants';

// eslint-disable-next-line default-param-last
const alertReducer = (state = DEFAULT_ALERT, { type, payload }) => {
  switch (type) {
    case types.SET_ALERT: {
      return payload;
    }
    default:
      return state;
  }
};

const confirmationReducer = (
  // eslint-disable-next-line default-param-last
  state = DEFAULT_CONFIRMATION,
  { type, payload }
) => {
  switch (type) {
    case types.SET_CONFIRMATION: {
      return payload;
    }
    default:
      return state;
  }
};

export default combineReducers({
  alert: alertReducer,
  confirmation: confirmationReducer,
});
