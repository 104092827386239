import React, { PureComponent } from 'react';

class Jyver extends PureComponent {
  static format(phoneNumberString) {
    const cleaned = `${phoneNumberString}`.replace(/\D/g, '');
    const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      const code = match[1] ? '+1 ' : '';
      return [code, '(', match[2], ') ', match[3], '-', match[4]].join('');
    }
    return null;
  }

  render() {
    const { fullName, phone, title, divisionName, employeeId } = this.props;

    return (
      <>
        <div className="jyver-full-name">{fullName}</div>
        {employeeId && <div className="jyver-employee-id">{employeeId}</div>}
        {phone && (
          <div data-testid="jyver-phone-number">{Jyver.format(phone)}</div>
        )}
        {title && <div>{title}</div>}
        {divisionName && (
          <div className="jyver-division-name">{divisionName}</div>
        )}
      </>
    );
  }
}

export default Jyver;
