/* OPERATIONS = REDUX THUNKS */

// import * as actions from './actions';

import isEmpty from 'lodash/isEmpty';
import { isFunction } from 'lodash';
import { datadogLogs } from '@datadog/browser-logs';
import HTTP from '../../../core/http';
import * as actions from './actions';
import { abortOperations } from '../abort';
import { QUERY_PARAM_LOOKUP } from '../../../lib/utils/queryParamUtils';
import { filterKeys } from '../../../containers/Filters/utils/sectionStructure';

export const buildSearchParams = ({
  searchQuery,
  apiSearchKey,
  determineSearchParams,
}) => {
  if (searchQuery) {
    if (isFunction(determineSearchParams)) {
      return determineSearchParams(searchQuery);
    }
    if (apiSearchKey) {
      return { [apiSearchKey]: searchQuery };
    }
  }
  return {};
};

export const fetchFilterItems =
  ({
    sectionKey,
    searchQuery,
    apiSearchKey,
    determineSearchParams,
    endpoint,
    params,
    parseData,
  }) =>
  async (dispatch, getState) => {
    await dispatch(abortOperations.abort(sectionKey));
    const {
      abort: { abortControllers },
      filters: { selectedFilterItems },
    } = getState();
    const { signal } = abortControllers[sectionKey];
    const { [sectionKey]: benign, ...otherSelectedFilterItems } =
      selectedFilterItems;
    const searchParams = buildSearchParams({
      searchQuery,
      apiSearchKey,
      determineSearchParams,
    });
    const selectedFilterItemParams = Object.entries(
      otherSelectedFilterItems
    ).reduce((accumulator, [key, value]) => {
      const { apiId, delimiter } = QUERY_PARAM_LOOKUP[key];
      return isEmpty(value) || !filterKeys.includes(key)
        ? accumulator
        : {
            ...accumulator,
            [apiId]: Array.from(value.keys()).join(delimiter || ','),
          };
    }, {});

    return HTTP.get(endpoint, {
      params: {
        ...params,
        ...searchParams,
        ...selectedFilterItemParams,
      },
      signal,
    })
      .then(({ data: { results } }) => {
        const mappedResults = parseData(results);

        return dispatch(
          actions.updateFilterItems({ [sectionKey]: mappedResults })
        );
      })
      .catch((error) => {
        if (signal && !signal.aborted) {
          datadogLogs.logger.error(
            '[FiltersSection] Error while fetching filter items',
            {
              endpoint,
              searchParams,
            },
            error
          );
        }
      });
  };
