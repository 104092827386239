import React, { useState } from 'react';
import { MobileDateTimePicker } from '@mui/x-date-pickers';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';

function DateTimePicker({
  value,
  maxDateTime,
  minDateTime,
  onAccept,
  openTo,
  timezone,
}) {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <MobileDateTimePicker
      open={isOpen}
      onOpen={() => setIsOpen(true)}
      onClose={() => setIsOpen(false)}
      openTo={openTo}
      value={value}
      minDateTime={minDateTime}
      maxDateTime={maxDateTime}
      timezone={timezone}
      onAccept={onAccept}
      slots={{
        field: CalendarMonthIcon,
      }}
      slotProps={{
        onClick: () => {
          setIsOpen(true);
        },
      }}
      views={['year', 'month', 'day', 'hours', 'minutes']}
    />
  );
}

export default DateTimePicker;
