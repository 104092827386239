import React, { useState } from 'react';

import Link from '@mui/material/Link';
import { useDispatch } from 'react-redux';
import JobDetailsDrawer from '../../../containers/JobDetailsDrawer';
import { DEFAULT_VIEW_ASSOCIATE } from '../../../state/ducks/viewAssociate/constants';
import { setSuggestAssociateJob } from '../../../state/ducks/suggestAssociate/actions';
import { setViewAssociate } from '../../../state/ducks/viewAssociate/actions';
import { DEFAULT_SUGGEST_ASSOCIATE_JOB } from '../../../state/ducks/suggestAssociate/constants';

function JobID({ job }) {
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();
  const onClose = () => {
    setOpen(false);
  };
  return (
    <>
      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
      <Link
        component="button"
        onClick={() => {
          setOpen(true);
          dispatch(setSuggestAssociateJob(DEFAULT_SUGGEST_ASSOCIATE_JOB));
          dispatch(setViewAssociate(DEFAULT_VIEW_ASSOCIATE));
        }}
        className="job-reference-id"
        sx={{
          textAlign: 'left',
        }}
      >
        <div>{job.external_identifier}</div>
        <div>{job.advantage_source_system_name}</div>
      </Link>
      <JobDetailsDrawer job={job} open={open} onClose={onClose} />
    </>
  );
}

export default JobID;
