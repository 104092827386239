/* eslint-disable camelcase */
import React, { PureComponent } from 'react';

import { Box, Stack, Typography } from '@mui/material';

import { determineIconDetail, determineExceptionText } from '../utils';
import { EXCEPTIONS_TO_MATCH_DETAILS } from '../../SuggestAssociates/constants';

const buildIcon = (exception, exceptions) => {
  const { color, icon } = determineIconDetail(exception, exceptions);
  const { exception_text, text } = EXCEPTIONS_TO_MATCH_DETAILS[exception];
  const textToShow = determineExceptionText(exception_text, text, icon);
  return (
    <Box
      key={exception}
      css={{ marginBottom: '16px', display: 'flex', alignItems: 'center' }}
    >
      <i className="material-icons" style={{ color }}>
        {icon}
      </i>{' '}
      <Typography
        variant="body2"
        css={{ marginLeft: '5px' }}
        id={`match-details-${exception_text}`}
      >
        {textToShow}
      </Typography>
    </Box>
  );
};

class MatchDetails extends PureComponent {
  render() {
    const { exceptions } = this.props;

    return (
      <Stack
        id="match_details"
        css={{
          padding: '16px',
          backgroundColor: 'white',
          borderTop: 'outset',
        }}
      >
        <Typography
          variant="subtitle1"
          css={{ marginTop: '16px', marginBottom: '16px' }}
        >
          Match Details
        </Typography>
        {Object.keys(EXCEPTIONS_TO_MATCH_DETAILS).map((exception) =>
          buildIcon(exception, exceptions)
        )}
      </Stack>
    );
  }
}

export default MatchDetails;
