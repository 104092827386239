import React, { useEffect, useState } from 'react';
import { AccordionDetails, AccordionSummary } from '@mui/material';
import axios from 'axios';
import Chip from '@mui/material/Chip';
import Accordion from '@mui/material/Accordion';
import { format, startOfDay } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';
import { getAssociatePreferences } from '../../../services/user';
import {
  AccordionHeader,
  AssignmentDiv,
  FirstHomeStoreDiv,
  HomeStoreAddressWrapper,
  HomeStoreDiv,
  HomeStoreInfoWrapper,
  LastHomeStoreDiv,
  LeftSpan,
  PaddedDiv,
  PaddedRightSpan,
  RightSpan,
  RoundedBoxNoPadding,
  SelectedDay,
  StyledNoWork,
} from '../style';
import ShowError from './ShowError';
import { capitalizeWordsInAllCaps, scrollIntoView } from '../../../lib/utils';

function WorkPreferencesCollapsableHeader({ associate, job, selectedDay }) {
  const [expanded, setExpanded] = useState(false);
  const [workPreferences, setWorkPreferences] = useState(null);
  const [workPreferencesError, setWorkPreferencesError] = useState(null);

  const handleDayChange = (date) => {
    if (date) {
      const zonedDate = utcToZonedTime(date, job.store_location_timezone);
      const startOfDayInStoreTimezone = startOfDay(zonedDate);

      const effectiveDate = format(
        startOfDayInStoreTimezone,
        'yyyy-MM-dd HH:mm:ssXXX',
        {
          timeZone: job.store_location_timezone,
        }
      );
      getAssociatePreferences({
        userId: associate.id,
        effectiveDate,
        storeTimezone: job.store_location_timezone,
      })
        .then((results) => {
          setWorkPreferences(results);
        })
        .catch((err) => {
          if (!axios.isCancel(err)) {
            setWorkPreferencesError(
              err.message ||
                'There was a problem retrieving the Associate record'
            );
          }

          setWorkPreferencesError(err);
        });
    }
  };

  useEffect(() => {
    handleDayChange(selectedDay);
  }, [selectedDay]);

  useEffect(() => {
    scrollIntoView(expanded, 'work-preferences-accordion-details', 400);
  }, [expanded]);

  if (workPreferencesError) {
    return (
      <ShowError
        msg="Oops, something went wrong. Please try again."
        doRetry={() => {
          handleDayChange(job.job_start_after);
        }}
        fullHeight
      />
    );
  }

  return (
    <h3>
      <Accordion
        expanded={expanded}
        square
        onChange={() => setExpanded(!expanded)}
      >
        <AccordionSummary
          expandIcon={<i className="material-icons expand-icon">expand_more</i>}
          aria-controls="panel1b-content"
          id="panel1b-header"
        >
          <div id="work-preferences">
            <AccordionHeader>Work Preferences</AccordionHeader>
            <SelectedDay id="selected-day-work-preferences">
              {format(
                utcToZonedTime(selectedDay, job.store_location_timezone),
                'MMMM do, yyyy, EEEE',
                { timeZone: job.store_location_timezone }
              )}
            </SelectedDay>
          </div>
        </AccordionSummary>
        <AccordionDetails id="work-preferences-accordion-details">
          {workPreferences && (
            <>
              {associate.home_store_location && (
                <RoundedBoxNoPadding>
                  <PaddedDiv id="home-store">Home Store</PaddedDiv>
                  <AssignmentDiv />
                  <HomeStoreInfoWrapper>
                    <HomeStoreAddressWrapper>
                      <FirstHomeStoreDiv>
                        {`${associate.home_store_location.chain_name} `}
                        {associate.home_store_location.primary_self_identity
                          ? `- ${associate.home_store_location.primary_self_identity}`
                          : ''}
                      </FirstHomeStoreDiv>
                      <HomeStoreDiv>
                        {associate.home_store_location.address.address}
                      </HomeStoreDiv>
                      <LastHomeStoreDiv>
                        {`${capitalizeWordsInAllCaps(
                          associate.home_store_location.address.city
                        )}, ${associate.home_store_location.address.state} ${
                          associate.home_store_location.address.postal_code
                        }`}
                      </LastHomeStoreDiv>
                    </HomeStoreAddressWrapper>
                    {workPreferences.my_home_store_only && (
                      <PaddedRightSpan>
                        <Chip
                          color="warning"
                          label="Only"
                          id="home-store-only-chip"
                        />
                      </PaddedRightSpan>
                    )}
                  </HomeStoreInfoWrapper>
                </RoundedBoxNoPadding>
              )}
              <RoundedBoxNoPadding>
                <PaddedDiv>Team/Retailer</PaddedDiv>
                <AssignmentDiv />
                <PaddedDiv id="team-retailer">
                  <LeftSpan>{associate.division_name}</LeftSpan>
                </PaddedDiv>
              </RoundedBoxNoPadding>
              {!workPreferences.my_home_store_only && (
                <RoundedBoxNoPadding>
                  <PaddedDiv>Travel</PaddedDiv>
                  <AssignmentDiv />
                  <PaddedDiv id="max-commute-miles">
                    <LeftSpan>Willing to drive</LeftSpan>
                    <RightSpan>
                      {workPreferences.maximum_commute_miles !== 0
                        ? `${workPreferences.maximum_commute_miles} miles`
                        : 'Unlimited'}
                    </RightSpan>
                  </PaddedDiv>
                  <PaddedDiv id="overnight-stay">
                    <LeftSpan>Travel and stay overnight</LeftSpan>
                    <RightSpan>
                      {workPreferences.allow_overnight_stay ? 'Yes' : 'No'}
                    </RightSpan>
                  </PaddedDiv>
                </RoundedBoxNoPadding>
              )}
              <RoundedBoxNoPadding>
                <PaddedDiv>Availability</PaddedDiv>
                <AssignmentDiv />
                <PaddedDiv id="preferred-weekly-hours">
                  <LeftSpan>Preferred hours a week</LeftSpan>
                  <RightSpan>
                    {workPreferences.preferred_weekly_hours} hours
                  </RightSpan>
                </PaddedDiv>
                {workPreferences.day_availability.length === 0 ? (
                  <StyledNoWork id="no-work-preferences-div">
                    No availability for this associate
                  </StyledNoWork>
                ) : (
                  workPreferences.day_availability.map(
                    (availability, index) => {
                      const startWithTz = utcToZonedTime(
                        availability.start,
                        job.store_location_timezone
                      );
                      const endWithTz = utcToZonedTime(
                        availability.end,
                        job.store_location_timezone
                      );
                      const formattedStartTime = format(startWithTz, 'h:mm a', {
                        timeZone: job.store_location_timezone,
                      });
                      const formattedEndTime = format(endWithTz, 'h:mm a', {
                        timeZone: job.store_location_timezone,
                      });
                      const formattedEndDisplayTime =
                        formattedEndTime !== '12:00 AM'
                          ? formattedEndTime
                          : '11:59 PM';

                      return (
                        <PaddedDiv
                          key={availability.start}
                          id={`availability-${index}`}
                        >
                          <LeftSpan>
                            {format(
                              utcToZonedTime(
                                selectedDay,
                                job.store_location_timezone
                              ),
                              'EEEE',
                              { timeZone: job.store_location_timezone }
                            )}
                          </LeftSpan>
                          <RightSpan>
                            {`${formattedStartTime} - ${formattedEndDisplayTime}`}
                          </RightSpan>
                        </PaddedDiv>
                      );
                    }
                  )
                )}
              </RoundedBoxNoPadding>
            </>
          )}
          {!workPreferences && (
            <StyledNoWork id="no-work-preferences-div">
              No availability for this associate
            </StyledNoWork>
          )}
        </AccordionDetails>
      </Accordion>
    </h3>
  );
}

export default WorkPreferencesCollapsableHeader;
