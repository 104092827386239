import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import * as types from './types';

const accessReducer = (
  // eslint-disable-next-line default-param-last
  state = {
    isAuthorized: true,
  },
  action
) => {
  switch (action.type) {
    case types.SET_IS_AUTHORIZED:
      return {
        ...state,
        isAuthorized: action.payload,
      };
    default:
      return state;
  }
};

export default persistReducer(
  {
    key: 'authorization',
    storage,
  },
  accessReducer
);
