import HTTP from '../../core/http';
import { objectKeysCamelToSnake } from '../case';

export function getAssociate({ id, fullName, externalId }, cancelToken) {
  return new Promise((resolve, reject) => {
    HTTP.get('users/', {
      cancelToken,
      params: objectKeysCamelToSnake({
        fullName,
        id,
        externalId,
        status: 'active',
        ordering: 'first_name,middle_name,last_name',
      }),
    })
      .then(({ data }) => {
        try {
          if (data) {
            resolve(data.results);
          } else {
            reject(new Error('No matching results'));
          }
        } catch (e) {
          reject(e);
        }
      })
      .catch((e) => {
        reject(e);
      });
  });
}

export function getAssociateSchedule(
  { userId, effectiveDate, storeTimezone },
  cancelToken
) {
  return new Promise((resolve, reject) => {
    HTTP.get(`/jobs/user/${userId}/schedule/`, {
      cancelToken,
      params: {
        effective_date: effectiveDate,
        timezone: storeTimezone,
      },
    }).then(({ data }) => {
      try {
        if (data) {
          resolve(data);
        } else {
          reject(new Error('No jobs associated with associate'));
        }
      } catch (e) {
        reject(e);
      }
    });
  });
}

export function getAssociateTimeOff(
  { userId, effectiveDate, storeTimezone },
  cancelToken
) {
  return new Promise((resolve, reject) => {
    HTTP.get(`/availability/unavailable-time/user/${userId}/schedule/`, {
      cancelToken,
      params: {
        effective_date: effectiveDate,
        timezone: storeTimezone,
      },
    }).then(({ data }) => {
      try {
        if (data) {
          resolve(data);
        } else {
          reject(new Error('No jobs associated with associate'));
        }
      } catch (e) {
        reject(e);
      }
    });
  });
}

export function getAssociatePreferences(
  { userId, effectiveDate, storeTimezone },
  cancelToken
) {
  return new Promise((resolve, reject) => {
    HTTP.get(`/availability/scheduling/user/${userId}/day-preferences/`, {
      cancelToken,
      params: {
        effective_date: effectiveDate,
        timezone: storeTimezone,
      },
    })
      .then(({ data }) => {
        try {
          if (data) {
            resolve(data);
          } else {
            reject(new Error('Error getting associate day preferences'));
          }
        } catch (e) {
          reject(e);
        }
      })
      .catch(() => {
        resolve(null);
      });
  });
}

export function getAssociateCertifications(userId, cancelToken) {
  return new Promise((resolve, reject) => {
    HTTP.get(`/credentials/user/${userId}/`, {
      cancelToken,
    }).then(({ data }) => {
      try {
        if (data) {
          resolve(data);
        } else {
          reject(new Error('Error getting associate certifications'));
        }
      } catch (e) {
        reject(e);
      }
    });
  });
}
