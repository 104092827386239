import { createTheme } from '@mui/material/styles';
import { hovered, offwhite, selected } from './colors';

export const desktopHDCutoff = '1279px';
export const desktopCutoff = '1087px';
export const tabletCutoff = '768px';
export const mobileCutoff = '425px';
const primaryFont = 'Proxima Nova';
const secondaryFont = 'Titillium Web';
const fontWeightRegular = 400;
const fontWeightSemiBold = 600;
const palette = {
  primary: {
    main: '#0090ba',
    light: '#33a6c7',
    dark: '#006482',
  },
  secondary: {
    main: '#0b3b60',
    light: '#3b627f',
    dark: '#072943',
  },
  tertiary: {
    main: '#acf21f',
    light: '#e2ff5f',
    dark: '#77bf00',
  },
  warning: {
    main: '#ff7940',
    light: '#ff9b70',
  },
  text: {
    primary: '#243745',
    secondary: '#65727b',
    disabled: '#99a1a8',
  },
  divider: 'rgba(6, 26, 42, 0.12)',
  action: {
    active: 'rgba(6, 26, 42, 0.54)',
    hover: 'rgba(6, 26, 42, 0.04)',
    selected: 'rgba(6, 26, 42, 0.08)',
    disabled: 'rgba(6, 26, 42, 0.26)',
    disabledBackground: 'rgba(6, 26, 42, 0.12)',
    focus: 'rgba(6, 26, 42, 0.12)',
  },
};

export const theme = createTheme({
  palette,
  typography: {
    fontFamily: primaryFont,
    fontSize: 16,
    fontWeightLight: fontWeightRegular,
    fontWeightRegular,
    fontWeightMedium: fontWeightSemiBold,
    fontWeightBold: fontWeightSemiBold,
    h1: {
      fontFamily: secondaryFont,
      fontWeight: fontWeightRegular,
      fontSize: 96,
      letterSpacing: -1.5,
    },
    h2: {
      fontFamily: secondaryFont,
      fontWeight: fontWeightRegular,
      fontSize: 60,
      letterSpacing: -0.5,
    },
    h3: {
      fontFamily: secondaryFont,
      fontSize: 48,
    },
    h4: {
      fontFamily: secondaryFont,
      fontSize: 34,
      letterSpacing: 0.25,
    },
    h5: {
      fontFamily: secondaryFont,
      fontSize: 24,
    },
    h6: {
      fontFamily: secondaryFont,
      fontWeight: fontWeightSemiBold,
      fontSize: 20,
      letterSpacing: 0.15,
    },
    subtitle1: {
      fontFamily: secondaryFont,
      fontWeight: fontWeightSemiBold,
      fontSize: 16,
      letterSpacing: 0.15,
    },
    subtitle2: {
      fontFamily: secondaryFont,
      fontWeight: fontWeightSemiBold,
      fontSize: 14,
      letterSpacing: 0.1,
      color: '#243745',
    },
    subtitle3: {
      fontFamily: primaryFont,
      fontWeight: fontWeightSemiBold,
      fontSize: 16,
      lineHeight: 1.75,
      letterSpacing: 0.15,
    },
    subtitle4: {
      fontFamily: primaryFont,
      fontWeight: fontWeightSemiBold,
      fontSize: 14,
      lineHeight: 1.75,
      letterSpacing: 0.15,
    },
    body1: {
      fontSize: 16,
      letterSpacing: 0.15,
    },
    body2: {
      fontSize: 14,
      letterSpacing: 0.17,
      color: '#243745',
    },
    button: {
      fontWeight: fontWeightSemiBold,
      fontSize: 14,
      lineHeight: '24px',
      letterSpacing: '0.4px',
      textTransform: 'uppercase',
    },
    caption: {
      fontSize: 12,
      letterSpacing: '0.4px',
    },
    overline: {
      fontSize: 12,
      letterSpacing: '1px',
      textTransform: 'uppercase',
    },
  },
  components: {
    MuiAutocomplete: {
      styleOverrides: {
        root: {
          '& label': {
            fontSize: 14,
          },
        },
        input: {
          fontSize: 14,
        },
        noOptions: {
          fontSize: 14,
        },
      },
    },
    MuiDataGrid: {
      styleOverrides: {
        root: {
          backgroundColor: 'white',
        },
        columnHeaderTitle: {
          fontFamily: secondaryFont,
          lineHeight: '24px',
          letterSpacing: '0.17px',
        },
        columnHeader: {
          padding: '8px',
        },
        cell: {
          display: 'block',
          padding: '8px',
          '&:focus-within': {
            outline: 'none',
          },
        },
        row: {
          '&:nth-child(odd)': {
            backgroundColor: offwhite,
          },
          '&:hover': {
            backgroundColor: hovered,
          },
          height: '120px',
        },
        selected: {
          backgroundColor: selected,
        },
      },
    },
    MuiDrawer: {
      styleOverrides: {
        root: {
          fontSize: 16,
        },
      },
    },
    MuiAlert: {
      styleOverrides: {
        filledSuccess: {
          fontWeight: 400,
        },
        filledError: {
          fontWeight: 400,
        },
        filledInfo: {
          fontWeight: 400,
        },
      },
    },
    MuiAccordion: {
      defaultProps: {
        disableGutters: true,
      },
      styleOverrides: {
        root: {
          borderTop: '1px solid rgba(0, 0, 0, .125)',
          '&:before': {
            display: 'none',
          },
        },
      },
    },
    MuiLink: {
      styleOverrides: {
        root: {
          color: palette.secondary.main,
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          color: 'white',
          '&.active': {
            color: palette.tertiary.main,
          },
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        filled: {
          color: 'white',
        },
      },
    },
  },
});
