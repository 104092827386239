export const DEFAULT_SUGGEST_ASSOCIATE_JOB = {
  id: null,
  job_start_after: null,
};

export const DEFAULT_ADVANCED_SEARCH = {
  isAvailable: true,
  withinCommuteMiles: true,
  isCertified: true,
  allowOvernight: true,
  previouslyDeclined: true,
  milesRadius: '20',
};

export const ADVANCED_SEARCH_CACHE_KEY =
  'boostRetailSuggestAssociateAdvancedSearch';
