import { combineReducers } from 'redux';
import store from 'store2';
import * as types from './types';
import {
  DEFAULT_ADVANCED_SEARCH,
  DEFAULT_SUGGEST_ASSOCIATE_JOB,
  ADVANCED_SEARCH_CACHE_KEY,
} from './constants';

const jobReducer = (
  // eslint-disable-next-line default-param-last
  state = DEFAULT_SUGGEST_ASSOCIATE_JOB,
  action
) => {
  switch (action.type) {
    case types.SET_SUGGEST_ASSOCIATE_JOB: {
      return {
        ...state,
        ...action.payload,
      };
    }
    default:
      return state;
  }
};

const advancedSearchReducer = (
  // eslint-disable-next-line default-param-last
  state = store.get(ADVANCED_SEARCH_CACHE_KEY) || DEFAULT_ADVANCED_SEARCH,
  action
) => {
  switch (action.type) {
    case types.SET_ADVANCED_SEARCH: {
      const advancedSearch = {
        ...state,
        ...action.payload,
      };
      store.set(ADVANCED_SEARCH_CACHE_KEY, advancedSearch);
      return advancedSearch;
    }
    default:
      return state;
  }
};

export default combineReducers({
  job: jobReducer,
  advancedSearch: advancedSearchReducer,
});
