/* eslint-disable camelcase */
import React, { useState } from 'react';
import Chip from '@mui/material/Chip';
import { Stack, Tooltip } from '@mui/material';
import { InfoOutlined } from '@mui/icons-material';
import { StatusChipWrapper } from '../style';
import { statusAttributesMapping } from '../constants';
import { getJobDeclineReason } from '../../../services/jobs';

const fromHomeSystem = 'From Home System';

function JobStatusChip({ jobId, status, jobDetails, variant }) {
  const [declineReason, setDeclineReason] = useState(null);
  const [open, setOpen] = useState(false);
  const onOpen = () => {
    if (!declineReason && !open) {
      getJobDeclineReason(jobId)
        .then(({ decline_reason_text, decline_reason, user }) => {
          const declineReasonText =
            decline_reason === fromHomeSystem
              ? decline_reason_text
              : decline_reason;
          setDeclineReason(`${user}: ${declineReasonText}`);
          setOpen(true);
        })
        .catch(() => {
          setDeclineReason('Error retrieving decline reason');
        });
    }
    setOpen(true);
  };
  if (statusAttributesMapping[status]) {
    const { color, label } = statusAttributesMapping[status];
    if (label === statusAttributesMapping.jyver_replied_no.label) {
      return (
        <StatusChipWrapper jobDetails={jobDetails}>
          <Chip
            label={
              <Stack direction="row" alignItems="center" spacing={0.5}>
                <div>{label}</div>
                <Tooltip
                  open={open}
                  onClose={() => setOpen(false)}
                  title={declineReason}
                  disableFocusListener
                  disableTouchListener
                  placement="top"
                  onOpen={onOpen}
                  arrow
                >
                  <InfoOutlined id="decline-tooltip" fontSize="inherit" />
                </Tooltip>
              </Stack>
            }
            id={`job_status_chip_${label}`}
            variant={variant}
            size="small"
            color={color}
          />
        </StatusChipWrapper>
      );
    }
    return (
      <StatusChipWrapper jobDetails={jobDetails}>
        <Chip
          label={label}
          id={`job_status_chip_${label}`}
          variant={variant}
          size="small"
          color={color}
        />
      </StatusChipWrapper>
    );
  }
  return '';
}

export default JobStatusChip;
