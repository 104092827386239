/* eslint-disable camelcase */
import React, { useState } from 'react';

import { useDispatch } from 'react-redux';
import {
  Assign,
  AssignButton,
  AssociateDetails,
  BoldHeader,
  HeaderWrapper,
} from '../style';
import { BlurHandler, LoadingWrapper } from '../../../styles/components';
import { putAssociate } from '../../../services/associates';
import { jobsOperations } from '../../../state/ducks/jobs';
import { formatErrorFromSubsystem } from '../../../lib/utils';
import { setConfirmation } from '../../../state/ducks/dialog/actions';
import { setToast } from '../../../state/ducks/toast/actions';
import Spinner from '../../Spinner';
import { ASSOCIATE_ASSIGNED_SUCCESS_TEXT } from '../../../containers/Home/constants';
import { setViewAssociate } from '../../../state/ducks/viewAssociate/actions';
import { DEFAULT_VIEW_ASSOCIATE } from '../../../state/ducks/viewAssociate/constants';
import { setSuggestAssociateJob } from '../../../state/ducks/suggestAssociate/actions';
import { DEFAULT_SUGGEST_ASSOCIATE_JOB } from '../../../state/ducks/suggestAssociate/constants';

function Header({
  associate,
  onClose,
  jobId,
  hideAssignButton,
  jobExternalIdentifier,
  homeSystem,
}) {
  const [isLoading, setIsLoading] = useState(false);

  const dispatch = useDispatch();

  const handleCloseDetailsDrawer = () => {
    onClose();
  };

  const handleAssignClick = (associateId, ignoreWarnings = false) => {
    setIsLoading(true);
    putAssociate({ associateId, jobId, ignoreWarnings })
      .then(() => {
        dispatch(setToast({ message: ASSOCIATE_ASSIGNED_SUCCESS_TEXT }));
        setIsLoading(false);
        dispatch(setViewAssociate(DEFAULT_VIEW_ASSOCIATE));
        dispatch(setSuggestAssociateJob(DEFAULT_SUGGEST_ASSOCIATE_JOB));
        dispatch(jobsOperations.fetchJobs());
      })
      .catch(
        ({
          response: {
            data: { error_code, detail },
          },
        }) => {
          setIsLoading(false);
          if (error_code === 'ValidationWarning') {
            dispatch(
              setConfirmation({
                dialog: {
                  title: 'Warning',
                  text: detail,
                },
                button: {
                  onClick: () => {
                    handleAssignClick(associate.id, true);
                  },
                  text: 'Continue',
                },
              })
            );
            return;
          }
          dispatch(
            setToast({
              message: formatErrorFromSubsystem(
                'Error when assigning or un-assigning',
                detail,
                jobExternalIdentifier,
                homeSystem
              ),
              error: true,
            })
          );
        }
      );
  };

  return (
    <HeaderWrapper>
      {!isLoading && (
        <>
          <button className="close-drawer" onClick={handleCloseDetailsDrawer}>
            <i className="material-icons">close</i>
          </button>
          <div
            className="content-wrapper"
            style={{
              width: '100%',
              paddingBottom: '16px',
            }}
          >
            <BoldHeader id="associate_details_full_name">
              {associate.full_name}
            </BoldHeader>
            <AssociateDetails id="associate_details_employee_id">
              {associate.external_id}
            </AssociateDetails>
            <AssociateDetails>{associate.profile.title}</AssociateDetails>
            <AssociateDetails>{associate.division_name}</AssociateDetails>
            <AssociateDetails>{associate.profile.address}</AssociateDetails>
            <AssociateDetails>
              {associate.profile.city}, {associate.profile.state},{' '}
              {associate.profile.postal_code}
            </AssociateDetails>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <div style={{ width: '50%' }}>
                <AssociateDetails>{associate.email}</AssociateDetails>
                <AssociateDetails>
                  {associate.phone_number || 'No phone number provided.'}
                </AssociateDetails>
              </div>
              {!hideAssignButton && (
                <div
                  style={{
                    width: '50%',
                    display: 'flex',
                    justifyContent: 'flex-end',
                  }}
                >
                  <AssignButton
                    type="submit"
                    className="matched-associate-details-assign-button"
                    disabled={false}
                    onClick={() => {
                      handleAssignClick(associate.id);
                    }}
                  >
                    <Assign>ASSIGN</Assign>
                  </AssignButton>
                </div>
              )}
            </div>
          </div>
        </>
      )}
      {isLoading && (
        <LoadingWrapper>
          <Spinner height="60" width="60" color="rgba(0, 0, 0, 0.1)" />
        </LoadingWrapper>
      )}
      <BlurHandler />
    </HeaderWrapper>
  );
}

export default Header;
