import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useDeepCompareEffect from 'use-deep-compare-effect';
import { jobsActions, jobsOperations } from '../../../../state/ducks/jobs';
import JobTable from '../../../../components/JobTable/JobTable';
import { changeCurrentPage } from '../../../../state/ducks/jobs/actions';
import { fetchJobs } from '../../../../state/ducks/jobs/operations';

function JobsList() {
  const jobs = useSelector((state) => state.jobs.list);
  const sort = useSelector((state) => state.jobs.sort);
  const selectedFilterItems = useSelector(
    (state) => state.filters.selectedFilterItems
  );
  const { results, isFetching, errorCode, page } = jobs;
  const dispatch = useDispatch();

  useDeepCompareEffect(() => {
    dispatch(fetchJobs());
  }, [selectedFilterItems, page, sort]);

  return (
    <JobTable
      jobs={results}
      isFetching={isFetching}
      errorCode={errorCode}
      currentPage={page}
      onPageChange={(currentPage) => {
        dispatch(changeCurrentPage(currentPage));
      }}
      onSortChange={(ordering) => {
        dispatch(jobsActions.changeSort(ordering));
        dispatch(jobsActions.changeCurrentPage(0));
        dispatch(jobsOperations.fetchJobs());
      }}
    />
  );
}

export default JobsList;
