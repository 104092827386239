import React, { PureComponent } from 'react';
import Link from '@mui/material/Link';
import LaunchIcon from '@mui/icons-material/Launch';
import { Box } from '@mui/material';

export default class JobAddress extends PureComponent {
  render() {
    const { name, address, details, selfIdentity } = this.props;
    const fullAddress = [address, details].join(', ');

    return (
      <Link
        href={`https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(
          fullAddress
        )}`}
        target="_blank"
        rel="noopener noreferrer"
        sx={{ display: 'flex', justifyContent: 'space-between', gap: '16px' }}
      >
        <Box>
          <div>
            {name} {selfIdentity && ` (${selfIdentity})`}
          </div>
          <div>{address}</div>
          <div>{details}</div>
        </Box>
        <Box>
          <LaunchIcon
            fontSize="small"
            sx={{ color: (theme) => theme.palette.secondary.main }}
          />
        </Box>
      </Link>
    );
  }
}
