import React, { useEffect, useState } from 'react';

import { getJobDetails } from 'services/jobs';

import { useSelector } from 'react-redux';
import { Stack, TablePagination, Typography } from '@mui/material';
import { DataGrid, useGridApiRef } from '@mui/x-data-grid';
import { concatenateAndPrepend, convertMinsToHrsMins } from '../../lib/utils';
import Associate from './components/Associate';
import JobDateInfo from './components/JobDateInfo';
import { StyledDataGridBox } from './style';
import JobID from './components/JobID';
import JobTimeInfo from './components/JobTimeInfo';
import JobAddress from './components/JobAddress';
import { JOBS_LIST_PAGE_SIZE } from '../../core/config';
import { ErrorWrapper } from '../ErrorPage/style';
import JobStatus from './components/JobStatus';
import { SHOULD_NOT_SHOW_BANNER } from '../../containers/Banner/constants';

function JobTable(props) {
  const {
    jobs,
    isFetching,
    errorCode,
    // numberOfRecords,
    currentPage,
    onPageChange,
    onSortChange,
  } = props;
  const isOpen = useSelector((state) => state.filters.visibility.isOpen);
  const headerHeight = SHOULD_NOT_SHOW_BANNER ? 115 : 135;
  const getDataGridHeight = () => window.innerHeight - headerHeight;
  const getDataGridWidth = () => window.innerWidth - 396.81;
  const [height, setHeight] = useState(getDataGridHeight());
  const [width, setWidth] = useState(getDataGridWidth());
  window.addEventListener('resize', () => {
    setHeight(getDataGridHeight());
    setWidth(getDataGridWidth());
  });
  const [rows, setRows] = useState([]);
  const gridApiRef = useGridApiRef();

  const getUpdatedJob = (id) => {
    getJobDetails(id).then((updatedJob) => {
      if (rows) {
        gridApiRef.current.updateRows([
          {
            id: updatedJob.id,
            projectId: `${updatedJob.external_project_identifier} - ${updatedJob.name}`,
            assignmentId: updatedJob,
            associate: updatedJob,
            status_central: updatedJob,
            date: updatedJob,
            time: updatedJob,
            duration: convertMinsToHrsMins(
              updatedJob.estimated_time,
              false,
              true
            ),
            location: updatedJob,
            storeLocationTimezone: updatedJob.store_location_timezone,
          },
        ]);
      }
    });
  };

  useEffect(() => {
    setRows(
      jobs.map((job) => ({
        id: job.id,
        projectId: `${job.external_project_identifier} - ${job.name}`,
        assignmentId: job,
        associate: job,
        status_central: job,
        date: job,
        time: job,
        duration: convertMinsToHrsMins(job.estimated_time, false, true),
        location: job,
        storeLocationTimezone: job.store_location_timezone,
      }))
    );
  }, [jobs]);

  if (errorCode && errorCode !== 404 && !isFetching) {
    return (
      <ErrorWrapper>
        <Typography variant="h6">Woopsie Daisy!</Typography>
        <Typography variant="body1">
          Something that should never happen seems to have happened and
          we&apos;re terribly sorry.
        </Typography>
      </ErrorWrapper>
    );
  }

  const columns = [
    {
      field: 'projectId',
      headerName: 'PROJECT ID & NAME',
      minWidth: 300,
      flex: 1,
      sortable: false,
    },
    {
      field: 'assignmentId',
      headerName: 'ASSIGNMENT ID',
      minWidth: 120,
      flex: 1,
      renderCell: ({ value: job }) => <JobID job={job} />,
      sortable: false,
    },
    {
      field: 'associate',
      headerName: 'ASSOCIATE',
      minWidth: 275,
      flex: 1,
      renderCell: ({ value: job }) => (
        <Associate job={job} getUpdatedJob={getUpdatedJob} />
      ),
      sortOrder: 'user__first_name,job_start_after,job_finish_before',
    },
    {
      field: 'status_central',
      headerName: 'STATUS',
      minWidth: 110,
      flex: 1,
      renderCell: ({ value: job }) => (
        <JobStatus
          jobId={job.id}
          status={job.status_central}
          statusAttributes={job.status_attributes}
          confirmationStatus={job.confirmation_status}
        />
      ),
      sortable: false,
    },
    {
      field: 'date',
      headerName: 'DATE',
      minWidth: 130,
      flex: 1,
      renderCell: ({ value: job }) => (
        <JobDateInfo
          id={job.id}
          status={job.status_central}
          startDate={job.job_start_after}
          storeTimezone={job.store_location_timezone}
          createdJobStartAfter={job.created_job_start_after}
          createdJobFinishBefore={job.created_job_finish_before}
          advantageSourceSystemName={job.advantage_source_system_name}
          externalIdentifier={job.external_identifier}
          getUpdatedJob={getUpdatedJob}
        />
      ),
      sortOrder: 'job_start_after,job_finish_before',
    },
    {
      field: 'time',
      headerName: 'TIME',
      minWidth: 210,
      flex: 1,
      renderCell: ({ value: job }) => (
        <JobTimeInfo
          id={job.id}
          status={job.status_central}
          startDate={job.job_start_after}
          endDate={job.job_finish_before}
          createdJobStartAfter={job.created_job_start_after}
          createdJobFinishBefore={job.created_job_finish_before}
          eta={job.estimated_time}
          storeTimezone={job.store_location_timezone}
          advantageSourceSystemName={job.advantage_source_system_name}
          externalIdentifier={job.external_identifier}
          getUpdatedJob={getUpdatedJob}
        />
      ),
      sortable: false,
    },
    {
      field: 'location',
      headerName: 'LOCATION',
      minWidth: 220,
      flex: 1,
      sortOrder:
        'store_location__store_chain__name,store_location__profile__primary_self_identity,job_start_after,job_finish_before',
      renderCell: ({ value: job }) => (
        <JobAddress
          id={job.store_location_id}
          name={job.banner_name}
          address={job.store_location_address.address}
          details={`${job.store_location_address.city}, ${job.store_location_address.state}, ${job.store_location_address.postal_code}`}
          selfIdentity={job.store_location_self_identity}
        />
      ),
    },
  ];

  const sortMapping = {
    asc: '',
    desc: '-',
  };

  const staticSort = ['job_start_after', 'job_finish_before'];
  const sortOptions = {
    associate: ['user__first_name'],
    date: [],
    location: [
      'store_location__store_chain__name',
      'store_location__profile__primary_self_identity',
    ],
  };

  return (
    <StyledDataGridBox height={height} width={isOpen ? `${width}px` : '100%'}>
      <DataGrid
        apiRef={gridApiRef}
        rows={rows}
        columns={columns}
        getRowHeight={() => 'auto'}
        disableColumnMenu
        disableColumnFilter
        disableRowSelectionOnClick
        sortingMode="server"
        onSortModelChange={(gridSortModel) => {
          if (gridSortModel.length > 0) {
            const { field, sort } = gridSortModel[0];
            onSortChange(
              concatenateAndPrepend(
                sortMapping[sort],
                sortOptions[field],
                staticSort
              )
            );
          }
        }}
        paginationMode="server"
        // pageSizeOptions={[]}
        // rowCount={numberOfRecords}
        // paginationModel={{ page: currentPage, pageSize: JOBS_LIST_PAGE_SIZE }}
        // onPaginationModelChange={({ page }) => {
        //   onPageChange(page);
        // }}
        loading={isFetching}
        slots={{
          // eslint-disable-next-line react/no-unstable-nested-components
          noRowsOverlay: () => (
            <Stack
              height="100%"
              alignItems="center"
              id="no-projects-to-display"
              justifyContent="center"
            >
              No projects to display
            </Stack>
          ),
          // eslint-disable-next-line react/no-unstable-nested-components
          pagination: () => (
            <TablePagination
              page={currentPage}
              count={1000000000}
              onPageChange={(event, value) => {
                onPageChange(value);
              }}
              rowsPerPage={JOBS_LIST_PAGE_SIZE}
              rowsPerPageOptions={[]}
              labelDisplayedRows={({ from, to }) => `${from}–${to}`}
            />
          ),
        }}
      />
    </StyledDataGridBox>
  );
}

JobTable.defaultProps = {
  errorCode: null,
};

export default JobTable;
