/* eslint-disable camelcase */
import * as React from 'react';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Tooltip } from '@mui/material';
import SearchAssociates from './SearchAssociates';
import Jyver from './Jyver';
import { removeAssociate } from '../../../services/associates';
import {
  AssignAssociateWrapper,
  HoverableDiv,
  SearchAssociateWrapper,
  Seperator,
  SpinnerWrapper,
  SuggestAssociateButton,
  UnassignButton,
} from '../style';
import { formatErrorFromSubsystem } from '../../../lib/utils';
import { setSuggestAssociateJob } from '../../../state/ducks/suggestAssociate/actions';
import { DEFAULT_SUGGEST_ASSOCIATE_JOB } from '../../../state/ducks/suggestAssociate/constants';
import { setToast } from '../../../state/ducks/toast/actions';
import Spinner from '../../Spinner';
import { determineShowAssign } from '../utils';

function Associate({ job, getUpdatedJob }) {
  const [isHovering, setIsHovering] = useState(false);
  const [isActive, setIsActive] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const { user } = job;
  const showAssign = determineShowAssign(isHovering, isActive, isLoading);
  const disabled = job.status_central === 'Completed';
  return (
    <HoverableDiv
      onMouseEnter={() => {
        setIsHovering(true);
      }}
      onMouseLeave={() => {
        setIsHovering(false);
      }}
      className="job-associate-column"
    >
      {user && !showAssign ? (
        <Jyver
          jobId={job.id}
          avatarUrl={user.photo_thumb_url || ''}
          fullName={user.full_name}
          phone={user.phone_number}
          jobsCompleted={user.jobs_completed}
          title={user.title}
          divisionName={user.division_name}
          employeeId={user.external_id}
        />
      ) : (
        showAssign && (
          <Tooltip
            title={
              disabled
                ? 'Team Member cannot be assigned, reassigned, or unassigned for work that is completed.'
                : ''
            }
          >
            <AssignAssociateWrapper>
              <SearchAssociateWrapper className="search-associates-wrapper">
                <SearchAssociates
                  sx={{
                    float: 'left',
                    width: user || isLoading ? 144 : 180,
                  }}
                  jobId={job.id}
                  externalIdentifier={job.external_identifier}
                  homeSystem={job.advantage_source_system_name}
                  fullName={user ? user.full_name : null}
                  isLoading={(newIsLoading) => {
                    setIsLoading(newIsLoading);
                  }}
                  isActive={(newIsActive) => {
                    setIsActive(newIsActive);
                  }}
                  disabled={disabled}
                  getUpdatedJob={getUpdatedJob}
                />
                {isLoading && (
                  <SpinnerWrapper>
                    <Spinner height="100%" />
                  </SpinnerWrapper>
                )}
                {user && !isLoading && (
                  <UnassignButton
                    className="associate-unassign-button"
                    onClick={() => {
                      setIsLoading(true);
                      removeAssociate(job.id)
                        .then(() => {
                          dispatch(
                            setToast({
                              message: 'Associate was removed successfully!',
                            })
                          );
                          setIsLoading(false);
                          getUpdatedJob(job.id);
                        })
                        .catch(
                          ({
                            response: {
                              data: { error_code, detail },
                            },
                          }) => {
                            if (error_code === 'ValidationWarning') {
                              throw new Error(`${error_code} - ${detail}`);
                            }
                            dispatch(
                              setToast({
                                message: formatErrorFromSubsystem(
                                  'Error when assigning or un-assigning',
                                  detail,
                                  job.external_identifier,
                                  job.advantage_source_system_name
                                ),
                                error: true,
                              })
                            );
                            setIsLoading(false);
                          }
                        );
                    }}
                    disabled={disabled}
                  >
                    <i className="material-icons">close</i>
                  </UnassignButton>
                )}
              </SearchAssociateWrapper>
              <Seperator />
              <SuggestAssociateButton
                className="associate-suggest-button"
                onClick={async () => {
                  Promise.resolve(
                    dispatch(
                      setSuggestAssociateJob(DEFAULT_SUGGEST_ASSOCIATE_JOB)
                    )
                  ).then(() => {
                    dispatch(setSuggestAssociateJob(job));
                  });
                }}
                disabled={disabled}
              >
                SUGGEST ASSOCIATE
              </SuggestAssociateButton>
            </AssignAssociateWrapper>
          </Tooltip>
        )
      )}
    </HoverableDiv>
  );
}

export default Associate;
