import { isEqual } from 'lodash';
import { DEFAULT_SELECTED_FILTERS } from '../constants';

import { filterKeys } from './sectionStructure';

export const determineNumberOfFilters = (selectedFilters) =>
  Object.entries(selectedFilters)
    .filter(([key]) => filterKeys.includes(key))
    .reduce((accumulator, [key, filters]) => {
      const { size } = filters;
      return isEqual(filters, DEFAULT_SELECTED_FILTERS[key])
        ? accumulator
        : accumulator + size;
    }, 0);

const isDefaultDate = (key, selectedFilters) =>
  isEqual(selectedFilters[key], DEFAULT_SELECTED_FILTERS[key]);

export const isDefaultDates = (selectedFilters) =>
  isDefaultDate('activeRangeStart', selectedFilters) &&
  isDefaultDate('activeRangeEnd', selectedFilters);
