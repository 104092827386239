import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppBar, Box, IconButton, Toolbar } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import MenuIcon from '@mui/icons-material/Menu';
import FilterListOffIcon from '@mui/icons-material/FilterListOff';
import FilterListIcon from '@mui/icons-material/FilterList';

import Tabs from 'containers/Header/Tabs';
import Feature from 'components/Feature';
import { ReactComponent as BoostRetailLogo } from '../../assets/header/boost-retail-logo.svg';
import { coreActions } from '../../state/ducks/core';
import { filtersActions } from '../../state/ducks/filters';

import { DEFAULT_SELECTED_FILTERS } from '../Filters';
import { determineNumberOfFilters } from '../Filters/utils';
import ClearFiltersCounter from '../Filters/components/ClearFiltersCounter';
import { DEFAULT_FILTER_QUERIES } from '../Filters/constants';
import { VIEWS } from '../../core/config';
import { Banner } from '../Banner';

function Header({ hasFilters }) {
  const isMainMenuOpen = useSelector((state) => state.core.isMainMenuOpen);
  const dispatch = useDispatch();
  const selectedFilterItems = useSelector(
    (state) => state.filters.selectedFilterItems
  );
  const numberOfActiveFilters = determineNumberOfFilters(selectedFilterItems);
  const areFiltersOpen = useSelector(
    (state) => state.filters.visibility.isOpen
  );
  const toggleMenu = () => {
    dispatch(coreActions.setShowMainMenu(!isMainMenuOpen));
  };
  const toggleFiltersVisibility = () => {
    dispatch(filtersActions.setShowFilters(!areFiltersOpen));
  };
  const theme = useTheme();

  return (
    <AppBar
      position="fixed"
      css={{
        zIndex: theme.zIndex.drawer + 1,
        backgroundColor: theme.palette.secondary.main,
      }}
    >
      <Banner />
      <Toolbar
        css={{
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <Box css={{ display: 'flex' }}>
          <IconButton
            id="main-navigational-menu-button"
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            css={{
              marginRight: 1,
            }}
            onClick={() => {
              toggleMenu();
            }}
          >
            <MenuIcon />
          </IconButton>
          <IconButton href={VIEWS.home} css={{ margin: 'auto 0' }}>
            <BoostRetailLogo />
          </IconButton>
          <Feature flag="showTabs">
            <Tabs />
          </Feature>
        </Box>
        <Box>
          {hasFilters && (
            <>
              {numberOfActiveFilters > 0 && (
                <ClearFiltersCounter
                  sectionKeys={['all']}
                  label="Applied Filters"
                  numberOfActiveFilters={numberOfActiveFilters}
                  onClear={() => {
                    dispatch(
                      filtersActions.updateSelectedFilterItems(
                        DEFAULT_SELECTED_FILTERS
                      )
                    );
                    dispatch(
                      filtersActions.updateFilterQueries(DEFAULT_FILTER_QUERIES)
                    );
                  }}
                />
              )}
              <IconButton
                id="filter-drawer-toggle-button"
                size="large"
                edge="end"
                color="inherit"
                aria-label="filters"
                css={{ marginLeft: 'auto' }}
                onClick={() => toggleFiltersVisibility()}
              >
                {areFiltersOpen ? <FilterListOffIcon /> : <FilterListIcon />}
              </IconButton>
            </>
          )}
        </Box>
      </Toolbar>
    </AppBar>
  );
}

Header.defaultProps = {
  hasFilters: false,
};

export default Header;
