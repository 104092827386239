import * as React from 'react';
import { AccordionDetails, AccordionSummary } from '@mui/material';
import { useEffect, useState } from 'react';
import axios from 'axios';
import Accordion from '@mui/material/Accordion';
import {
  differenceInMilliseconds,
  formatDuration,
  intervalToDuration,
  isSameDay,
  startOfDay,
} from 'date-fns';
import { utcToZonedTime, format, zonedTimeToUtc } from 'date-fns-tz';
import {
  getAssociateSchedule,
  getAssociateTimeOff,
} from '../../../services/user';
import {
  AccordionHeader,
  BlackSquare,
  DateBolder,
  OvernightWork,
  OvernightWorkWrapper,
  RedErrorOutlineOutlinedIcon,
  RoundedBox,
  SecondaryColorSpan,
  SecondaryFontSize,
  SelectedDay,
  StyledDiv,
  StyledNoWork,
  TimeDuration,
  TimeSpan,
} from '../style';
import ShowError from './ShowError';
import { standardDateFnsFormat } from '../../../services/date';

function ScheduleCollapsableHeader({ associate, job, selectedDay }) {
  const [unavailableTimes, setUnavailableTimes] = useState([]);
  const [overallTimeUnavailable, setOverallTimeUnavailable] = useState([]);
  const [timeOff, setTimeOff] = useState([]);
  const [unavailableTimesError, setUnavailableTimesError] = useState(null);
  const [timeOffError, setTimeOffError] = useState(null);

  useEffect(() => {
    const mergedArrays = unavailableTimes.concat(timeOff);

    mergedArrays.sort(
      (a, b) => new Date(a.datetime).getTime() - new Date(b.datetime).getTime()
    );

    setOverallTimeUnavailable(mergedArrays);
  }, [unavailableTimes, timeOff]);

  const handleDayChange = (date) => {
    if (date) {
      setOverallTimeUnavailable([]);
      setUnavailableTimes([]);
      setTimeOff([]);
      const effectiveDate = zonedTimeToUtc(
        startOfDay(utcToZonedTime(date, job.store_location_timezone)),
        job.store_location_timezone
      );
      getAssociateSchedule({
        userId: associate.id,
        effectiveDate,
        storeTimezone: job.store_location_timezone,
      })
        .then((results) => {
          const addedDateTimeList = results.map((result) => ({
            start_datetime: result.job_start_after,
            datetime: result.job_start_after,
            end_datetime: result.job_finish_before,
            store_location_timezone: result.store_location_timezone,
            isOvernight: !isSameDay(
              utcToZonedTime(
                new Date(result.job_start_after),
                job.store_location_timezone
              ),
              utcToZonedTime(
                new Date(result.job_finish_before),
                job.store_location_timezone
              )
            ),
            ...result,
          }));

          setUnavailableTimes(addedDateTimeList);
        })
        .catch((err) => {
          if (!axios.isCancel(err)) {
            setUnavailableTimesError(
              err.message ||
                'There was a problem retrieving the Associate record'
            );
          }

          setUnavailableTimesError(err);
        });
      getAssociateTimeOff({
        userId: associate.id,
        effectiveDate,
        storeTimezone: job.store_location_timezone,
      })
        .then((results) => {
          const addedDateTimeList = results.map((result) => ({
            datetime: result.start_datetime,
            ...result,
          }));
          setTimeOff(addedDateTimeList);
        })
        .catch((err) => {
          if (!axios.isCancel(err)) {
            setTimeOffError(
              err.message ||
                'There was a problem retrieving the Associate record'
            );
          }

          setTimeOffError(err);
        });
    }
  };

  useEffect(() => {
    handleDayChange(selectedDay);
  }, [selectedDay]);

  const calculateTimeFrame = (finishBeforeDate, startAfterDate) => {
    const beforeDate = new Date(finishBeforeDate);
    const afterDate = new Date(startAfterDate);

    const durationInMilliseconds = differenceInMilliseconds(
      beforeDate,
      afterDate
    );
    const duration = intervalToDuration({
      start: 0,
      end: durationInMilliseconds,
    });
    const formattedDuration = formatDuration(duration, {
      format: ['days', 'hours', 'minutes'],
    });
    return <SecondaryColorSpan>{`${formattedDuration}`}</SecondaryColorSpan>;
  };

  const determineTimeOff = (startTime, endTime) => {
    const startTimeWithTimezone = utcToZonedTime(
      new Date(startTime),
      job.store_location_timezone
    );
    const endTimeWithTimezone = utcToZonedTime(
      new Date(endTime),
      job.store_location_timezone
    );
    const timezoneAbr = format(startTimeWithTimezone, 'zzz', {
      timeZone: job.store_location_timezone,
    });

    const isAllDay =
      endTimeWithTimezone.getTime() - startTimeWithTimezone.getTime() >=
      24 * 60 * 60 * 1000;

    if (isAllDay) {
      return <DateBolder>All Day</DateBolder>;
    }

    return (
      <TimeSpan>
        {format(startTimeWithTimezone, 'h:mm a')}
        {' - '}
        {format(endTimeWithTimezone, 'h:mm a')}
        {` (${timezoneAbr})`}
      </TimeSpan>
    );
  };

  const formatTimezone = (timezone, utcDateTime) => {
    if (timezone) {
      const zonedDateTime = utcToZonedTime(utcDateTime, timezone);
      const offsetString = format(zonedDateTime, 'O', { timeZone: timezone });
      const zoneAbbr = format(zonedDateTime, 'zzz', { timeZone: timezone });
      const zoneFullName = timezone.split('/')[1].replace('_', ' ');

      return `(${offsetString}:00) ${zoneAbbr} - ${zoneFullName}`;
    }
    return '';
  };

  if (unavailableTimesError || timeOffError) {
    return (
      <ShowError
        msg="Oops, something went wrong. Please try again."
        doRetry={() => {
          handleDayChange(job.job_start_after);
        }}
        fullHeight
      />
    );
  }

  return (
    <h3>
      <Accordion defaultExpanded square>
        <AccordionSummary
          expandIcon={<i className="material-icons expand-icon">expand_more</i>}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <div>
            <AccordionHeader>Work Schedule</AccordionHeader>
            <SelectedDay id="selected-day">
              {format(
                utcToZonedTime(
                  new Date(selectedDay),
                  job.store_location_timezone
                ),
                'MMMM do, yyyy, EEEE',
                { timeZone: job.store_location_timezone }
              )}
            </SelectedDay>
          </div>
        </AccordionSummary>
        <AccordionDetails>
          {overallTimeUnavailable.length === 0 && (
            <StyledNoWork id="no-work-div">
              No scheduled work for this day
            </StyledNoWork>
          )}
          {overallTimeUnavailable.map((unavailableTime, index) => (
            <RoundedBox
              id={`rounded-box-${index}`}
              key={unavailableTime.datetime}
            >
              {unavailableTime.job_start_after ? (
                <>
                  {unavailableTime.isOvernight && (
                    <>
                      <OvernightWorkWrapper>
                        <RedErrorOutlineOutlinedIcon />
                        <OvernightWork>Overnight Work</OvernightWork>
                      </OvernightWorkWrapper>
                      <div>
                        <TimeSpan>
                          {'Starts '}
                          <BlackSquare />{' '}
                          {format(
                            utcToZonedTime(
                              new Date(unavailableTime.job_start_after),
                              job.store_location_timezone
                            ),
                            'eee, MMM d',
                            { timeZone: job.store_location_timezone }
                          )}
                        </TimeSpan>
                      </div>
                    </>
                  )}
                  <div>
                    <TimeSpan>
                      {`${format(
                        utcToZonedTime(
                          new Date(unavailableTime.job_start_after),
                          job.store_location_timezone
                        ),
                        'h:mm a',
                        { timeZone: job.store_location_timezone }
                      )} - ${format(
                        utcToZonedTime(
                          new Date(unavailableTime.job_finish_before),
                          job.store_location_timezone
                        ),
                        'h:mm a',
                        { timeZone: job.store_location_timezone }
                      )}`}
                    </TimeSpan>
                  </div>
                  <SecondaryFontSize>
                    {formatTimezone(
                      job.store_location_timezone,
                      unavailableTime.job_start_after
                    )}
                  </SecondaryFontSize>
                  <TimeDuration>
                    {calculateTimeFrame(
                      unavailableTime.job_finish_before,
                      unavailableTime.job_start_after
                    )}
                  </TimeDuration>
                  <SecondaryFontSize>
                    {unavailableTime.external_project_identifier}
                    {' - '}
                    {unavailableTime.name} - {unavailableTime.banner_name} (
                    {unavailableTime.store_location_self_identity})
                  </SecondaryFontSize>
                  <SecondaryFontSize>
                    {unavailableTime.store_location_address.address}
                  </SecondaryFontSize>
                  <SecondaryFontSize>
                    {unavailableTime.store_location_address.city},{' '}
                    {unavailableTime.store_location_address.state}{' '}
                    {unavailableTime.store_location_address.postal_code}
                  </SecondaryFontSize>
                  <SecondaryFontSize>
                    <StyledDiv>
                      <div>
                        {`Earliest Start: ${format(
                          utcToZonedTime(
                            new Date(unavailableTime.created_job_start_after),
                            job.store_location_timezone
                          ),
                          standardDateFnsFormat,
                          { timeZone: job.store_location_timezone }
                        )}`}
                      </div>
                      <div>
                        {`Latest Finish: ${format(
                          utcToZonedTime(
                            new Date(unavailableTime.created_job_finish_before),
                            job.store_location_timezone
                          ),
                          standardDateFnsFormat,
                          { timeZone: job.store_location_timezone }
                        )}`}
                      </div>
                    </StyledDiv>
                  </SecondaryFontSize>
                </>
              ) : (
                <>
                  <div>
                    {determineTimeOff(
                      unavailableTime.start_datetime,
                      unavailableTime.end_datetime
                    )}
                  </div>
                  <TimeDuration>
                    {calculateTimeFrame(
                      unavailableTime.end_datetime,
                      unavailableTime.start_datetime
                    )}
                  </TimeDuration>
                  <div>Time Off</div>
                </>
              )}
            </RoundedBox>
          ))}
        </AccordionDetails>
      </Accordion>
    </h3>
  );
}

export default ScheduleCollapsableHeader;
