import HTTP from '../../core/http';
import { objectKeysCamelToSnake } from '../case';
import { DEFAULT_ADVANCED_SEARCH } from '../../state/ducks/suggestAssociate/constants';

export function getMatchedAssociates(matchedAssociatesRequest, signal) {
  const { jobId } = matchedAssociatesRequest;
  return new Promise((resolve, reject) => {
    HTTP.get(`jobs/${jobId}/find-associates`, {
      params: objectKeysCamelToSnake({
        ...DEFAULT_ADVANCED_SEARCH,
        ...matchedAssociatesRequest,
        jobId: undefined,
      }),
      signal,
    })
      .then(({ data }) => {
        try {
          resolve(data);
        } catch (e) {
          reject(e);
        }
      })
      .catch((e) => {
        reject(e);
      });
  });
}

export function putAssociate({ associateId, jobId, ignoreWarnings = false }) {
  return new Promise((resolve, reject) => {
    HTTP.put(`jobs/${jobId}/concierge-claim/`, {
      user_id: associateId,
      ignore_warnings: ignoreWarnings,
    })
      .then(({ data }) => {
        resolve(data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function removeAssociate(jobId) {
  return new Promise((resolve, reject) => {
    HTTP.put(`jobs/${jobId}/concierge-unclaim/`, {})
      .then(({ data }) => {
        resolve(data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}
