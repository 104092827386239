import React from 'react';
import { Provider } from 'react-redux';
import { LicenseInfo } from '@mui/x-license-pro';

import { BrowserRouter } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';
import { createRoot } from 'react-dom/client';
import store, { persistor } from './state/store';
import * as serviceWorker from './serviceWorker';

import App from './App';
import GA from './services/ga';
import { MUI_PRO_LICENSE_KEY } from './core/config';

GA.load();

LicenseInfo.setLicenseKey(MUI_PRO_LICENSE_KEY);

const container = document.getElementById('root');
const root = createRoot(container);
root.render(
  <Provider store={store}>
    <PersistGate persistor={persistor}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </PersistGate>
  </Provider>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
