export const DEFAULT_ALERT = {};
export const DEFAULT_CONFIRMATION = {};

export const UPDATE_VERSION_ALERT = {
  dialog: {
    title: 'Update Required',
    text: 'To continue working, you must update to the latest version of Boost Retail. Click Install Now to continue.',
  },
  button: {
    onClick: () => {
      window.location.reload();
    },
    text: 'Install Now',
  },
};
