import React, { useEffect, useState } from 'react';
import axios from 'axios';

import { useDispatch, useSelector } from 'react-redux';
import { DrawerWrapper, LoadingWrapper } from '../../styles/components';
import Spinner from '../Spinner';
import Header from './components/Header';
import { getAssociate } from '../../services/user';
import MatchDetails from './components/MatchDetails';
import { DEFAULT_VIEW_ASSOCIATE } from '../../state/ducks/viewAssociate/constants';
import ScheduleCollapsableHeader from './components/ScheduleCollapsableHeader';
import MonthAndWeekdaySelector from './components/MonthDaySelector';
import ShowError from './components/ShowError';
import WorkPreferencesCollapsableHeader from './components/WorkPreferencesCollapsableHeader';
import CertificationsCollapsableHeader from './components/CertificationsCollapsableHeader';
import { setViewAssociate } from '../../state/ducks/viewAssociate/actions';

function AssociateDetails() {
  const selectedAssociate = useSelector((state) => state.viewAssociate);
  const suggestAssociate = useSelector((state) => state.suggestAssociate);
  const [associate, setAssociate] = useState(null);
  const [errorMessage, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedDay, setSelectedDay] = useState(
    suggestAssociate.job.job_start_after
  );
  const dispatch = useDispatch();
  const loadAssociate = (id, callBack) => {
    setIsLoading(true);

    getAssociate({ id })
      .then((data) => {
        setAssociate(data[0]);
        setIsLoading(false);

        if (callBack) callBack();
      })
      .catch((err) => {
        if (!axios.isCancel(err)) {
          setError(
            err.message || 'There was a problem retrieving the associate record'
          );
          setIsLoading(false);
        }

        setError(err.message);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    if (suggestAssociate.job.job_start_after) {
      setSelectedDay(suggestAssociate.job.job_start_after);
    }
  }, [suggestAssociate]);

  useEffect(() => {
    loadAssociate(selectedAssociate.user_id);
  }, [selectedAssociate.user_id]);

  const handleDayChange = (date) => {
    setSelectedDay(date);
  };

  if (errorMessage) {
    return (
      <ShowError
        msg={errorMessage}
        doRetry={() => {
          loadAssociate(selectedAssociate.user_id);
        }}
        fullHeight
      />
    );
  }
  return (
    <DrawerWrapper id="associate-details">
      {!isLoading ? (
        associate && (
          <>
            <Header
              isLoading
              associate={associate}
              associateDepartment={selectedAssociate.department}
              onClose={() => {
                dispatch(setViewAssociate(DEFAULT_VIEW_ASSOCIATE));
              }}
              jobId={suggestAssociate.job.id}
              jobExternalIdentifier={suggestAssociate.job.external_id}
              homeSystem={suggestAssociate.job.advantage_source_system_name}
              hideAssignButton={
                selectedAssociate.fromJobDetails ||
                selectedAssociate.hideAssignButton
              }
            />
            {selectedAssociate && (
              <>
                {!selectedAssociate.hideMatchDetails && (
                  <MatchDetails exceptions={selectedAssociate.exceptions} />
                )}
                <MonthAndWeekdaySelector
                  jobStartDate={selectedDay}
                  handleDayChange={handleDayChange}
                  timezone={suggestAssociate.job.store_location_timezone}
                />
                <ScheduleCollapsableHeader
                  associate={associate}
                  job={suggestAssociate.job}
                  selectedDay={selectedDay}
                />
                <WorkPreferencesCollapsableHeader
                  associate={associate}
                  job={suggestAssociate.job}
                  selectedDay={selectedDay}
                />
                <CertificationsCollapsableHeader associate={associate} />
              </>
            )}
          </>
        )
      ) : (
        <LoadingWrapper>
          <Spinner height="60" width="60" color="rgba(0, 0, 0, 0.1)" />
        </LoadingWrapper>
      )}
    </DrawerWrapper>
  );
}

export default AssociateDetails;
