import React from 'react';

import Link from '@mui/material/Link';
import ErrorPage from '../../components/ErrorPage';
import { ReactComponent as ErrorIcon } from '../../assets/empty-states/5xx-error.svg';

function NotFound404() {
  return (
    <ErrorPage icon={ErrorIcon}>
      The page you are trying to open doesn&#39;t seem to exist. Please try
      opening another page from the main menu on the left or if you believe this
      to be a mistake, please{' '}
      <Link
        href="https://helpdesk.asmnet.com"
        target="_blank"
        rel="noopener noreferrer"
      >
        submit a bug report
      </Link>
      .
    </ErrorPage>
  );
}

export default NotFound404;
