import styled from '@emotion/styled/macro';
import { manatee, secondaryText, watermelon, white } from '../theme/colors';
import { tabletCutoff } from '../theme';

export const ErrorWrapper = styled.div`
  padding: 25px 0 50px;

  svg {
    width: 300px;
    height: auto;
  }

  h1 {
    font-size: 2rem;
    font-weight: 500;
    color: ${secondaryText};

    @media only screen and (max-width: ${tabletCutoff}) {
      padding: 0 32px;
    }
  }

  h2 {
    margin-top: 15px;
    max-width: 400px;
    text-align: center;
    color: ${manatee};

    @media only screen and (max-width: ${tabletCutoff}) {
      padding: 0 32px;
    }
  }
`;

export const DrawerErrorWrapper = styled.div`
  width: 550px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: ${watermelon};

  ${({ fullHeight }) =>
    fullHeight
      ? `
    height: 100%;
  `
      : `
    margin: 32px 0;
  `}

  button {
    margin-top: 16px;
  }
`;

export const GeneralTag = styled.a`
  padding: 6px 12px;
  font-size: 14.8px;
  line-height: 1.2rem;
  font-weight: 600;
  border-radius: 5px;
  color: ${white};
`;

export const DrawerWrapper = styled.div`
  overflow-y: scroll;
  height: 100vh;
  background-color: rgba(11, 59, 96, 0.04);
  width: 550px;

  /* Add a padding to the bottom of mobile safari
     See: https://nicolas-hoizey.com/2015/02/viewport-height-is-taller-than-the-visible-part-of-the-document-in-some-mobile-browsers.html */
  @supports (-webkit-overflow-scrolling: touch) {
    padding-bottom: 110px;
  }

  .tabs {
    z-index: 10;

    a {
      padding: 0.8em 1em;
    }
  }
`;

export const LoadingWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: 64px 0;
  width: 550px;
`;

export const BlurHandler = styled.div`
  content: ' ';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: block;
  width: 50%;
  height: 100%;
  background: linear-gradient(
    90deg,
    rgba(246, 247, 249, 0.85) 70%,
    rgba(246, 247, 249, 0)
  );
`;
