import { subDays, addDays, format } from 'date-fns';

const FILTER_NAMES = [
  'activeRangeStart',
  'activeRangeEnd',
  'statuses',
  'brands',
  'states',
  'cityStates',
  'zipCodes',
  'jobTypes',
  'homeSystem',
  'jyvers',
  'stores',
  'tdLinx',
  'projectName',
  'projectId',
  'assignmentId',
  'bannerRetailer',
  'divisions',
  'callToAction',
  'associateType',
];

export const activeRangeStartDefault = subDays(new Date(), 14);
export const activeRangeEndDefault = addDays(new Date(), 60);

const activeRangeStartFormatted = format(activeRangeStartDefault, 'yyyy-MM-dd');
const activeRangeEndFormatted = format(activeRangeEndDefault, 'yyyy-MM-dd');

export const DEFAULT_SELECTED_FILTERS = {
  ...FILTER_NAMES.reduce(
    (accumulator, filterName) => ({ ...accumulator, [filterName]: new Map() }),
    {}
  ),
  activeRangeStart: new Map([
    [activeRangeStartFormatted, activeRangeStartFormatted],
  ]),
  activeRangeEnd: new Map([[activeRangeEndFormatted, activeRangeEndFormatted]]),
};

export const DEFAULT_FILTER_QUERIES = FILTER_NAMES.reduce(
  (accumulator, filterName) => ({ ...accumulator, [filterName]: '' }),
  {}
);

export const DATE_RANGE_FORMAT = 'YYYY-MM-DD';
export const DATE_FNS_RANGE_FORMAT = 'yyyy-MM-dd';

export const DATE_RANGE_REGEX = /^\d{4}-\d{2}-\d{2}$/;

export const STATES = {
  Alabama: 'AL',
  Alaska: 'AK',
  Arizona: 'AZ',
  Arkansas: 'AR',
  California: 'CA',
  Colorado: 'CO',
  Connecticut: 'CT',
  Delaware: 'DE',
  Florida: 'FL',
  Georgia: 'GA',
  Hawaii: 'HI',
  Idaho: 'ID',
  Illinois: 'IL',
  Indiana: 'IN',
  Iowa: 'IA',
  Kansas: 'KS',
  Kentucky: 'KY',
  Louisiana: 'LA',
  Maine: 'ME',
  Maryland: 'MD',
  Massachusetts: 'MA',
  Michigan: 'MI',
  Minnesota: 'MN',
  Mississippi: 'MS',
  Missouri: 'MO',
  Montana: 'MT',
  Nebraska: 'NE',
  Nevada: 'NV',
  'New Hampshire': 'NH',
  'New Jersey': 'NJ',
  'New Mexico': 'NM',
  'New York': 'NY',
  'North Carolina': 'NC',
  'North Dakota': 'ND',
  Ohio: 'OH',
  Oklahoma: 'OK',
  Oregon: 'OR',
  Pennsylvania: 'PA',
  'Rhode Island': 'RI',
  'South Carolina': 'SC',
  'South Dakota': 'SD',
  Tennessee: 'TN',
  Texas: 'TX',
  Utah: 'UT',
  Vermont: 'VT',
  Virginia: 'VA',
  Washington: 'WA',
  'West Virginia': 'WV',
  Wisconsin: 'WI',
  Wyoming: 'WY',
};
