import { EXCEPTIONS_TO_MATCH } from '../constants';

export const determineChipColorAndLabel = (exception) => {
  const label = EXCEPTIONS_TO_MATCH[exception]
    ? EXCEPTIONS_TO_MATCH[exception].exception_text
    : 'Unknown Exception';
  const previouslyDeclinedExceptionText =
    EXCEPTIONS_TO_MATCH.previously_declined.exception_text;
  const color = label === previouslyDeclinedExceptionText ? 'error' : 'warning';

  return { color, label };
};
