/* eslint-disable camelcase */
import React from 'react';
import { Grid, Typography } from '@mui/material';
import { useDispatch } from 'react-redux';
import { getAssociate } from '../../../services/user';
import { putAssociate } from '../../../services/associates';
import AsyncAutocomplete from '../../Select/AsyncAutocomplete';
import { ASSOCIATE_ASSIGNED_SUCCESS_TEXT } from '../../../containers/Home/constants';
import { formatErrorFromSubsystem } from '../../../lib/utils';
import { setConfirmation } from '../../../state/ducks/dialog/actions';
import { setToast } from '../../../state/ducks/toast/actions';

function SearchAssociate({
  jobId,
  fullName,
  isLoading,
  isActive,
  sx,
  disabled,
  externalIdentifier,
  homeSystem,
  getUpdatedJob,
}) {
  const initialValue = fullName || null;
  const dispatch = useDispatch();

  const handleAssociateClick = (
    associateId,
    errorCallback,
    ignoreWarnings = false
  ) => {
    isLoading(true);
    putAssociate({ associateId, jobId, ignoreWarnings })
      .then(() => {
        dispatch(setToast({ message: ASSOCIATE_ASSIGNED_SUCCESS_TEXT }));
        isLoading(false);
        isActive(false);
        getUpdatedJob(jobId);
      })
      .catch(
        ({
          response: {
            data: { error_code, detail },
          },
        }) => {
          if (error_code === 'ValidationWarning') {
            dispatch(
              setConfirmation({
                dialog: {
                  title: 'Warning',
                  text: detail,
                },
                button: {
                  onClick: () => {
                    handleAssociateClick(associateId, errorCallback, true);
                  },
                  text: 'Continue',
                },
              })
            );
            isLoading(false);
            isActive(true);
            errorCallback();
            return;
          }
          dispatch(
            setToast({
              message: formatErrorFromSubsystem(
                'Error when assigning or un-assigning',
                detail,
                externalIdentifier,
                homeSystem
              ),
              error: true,
            })
          );
          isLoading(false);
          isActive(true);
          errorCallback();
        }
      );
  };

  return (
    <AsyncAutocomplete
      label="Search Associate"
      noOptionsText="No Associate Found"
      sx={sx}
      initialValue={initialValue}
      onChange={({ id }, errorCallback) =>
        handleAssociateClick(id, errorCallback)
      }
      onInputChange={(inputValue, callback) => {
        const request = Number.isNaN(parseFloat(inputValue))
          ? { fullName: inputValue }
          : { externalId: inputValue };
        getAssociate(request).then((results) => {
          callback(
            results.map((result) => ({
              label: result.full_name,
              id: result.id,
              employeeId: result.external_id,
            }))
          );
        });
      }}
      isActive={isActive}
      disabled={disabled}
      renderOption={(props, option) => (
        <li {...props}>
          <Grid>
            <Typography variant="body2">{option.label}</Typography>

            {option.employeeId && (
              <Typography variant="body2" color="text.secondary">
                {option.employeeId}
              </Typography>
            )}
          </Grid>
        </li>
      )}
    />
  );
}

export default SearchAssociate;
