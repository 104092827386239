import store from 'store2';

import { updateAbilityByUser, clearAbility } from '../../core/ability';
import User from '../../lib/user';
import HTTP from '../../core/http';
import { URL, JWT_PAYLOAD_LOCAL_STORAGE_KEY } from '../../core/config';
import { removeAllFromCacheExceptStartsWith } from '../cache';

export default class AuthService {
  static accessTokenPayload = null;

  static setAccessTokenPayload(token) {
    // JWT's are comprised of 3 parts, header, payload and signature
    // we only want to save the payload bit, so we grab index 1 here
    const payload = token.split('.')[1];

    store.set(JWT_PAYLOAD_LOCAL_STORAGE_KEY, payload);
    this.accessTokenPayload = payload;

    // Every time we update the access payload in localStorage we
    // want to make sure we update all related services in our app
    // with the latest information we have on the user:
    const currentUser = this.getCurrentUser();

    if (currentUser) {
      // update the users access permissions
      updateAbilityByUser(currentUser);
    }
  }

  static getAccessTokenPayload() {
    if (!this.accessTokenPayload) {
      this.accessTokenPayload = store.get(JWT_PAYLOAD_LOCAL_STORAGE_KEY);
    }
    return this.accessTokenPayload || null;
  }

  static getDecodedAccessTokenPayload() {
    try {
      if (this.getAccessTokenPayload()) {
        return JSON.parse(window.atob(this.getAccessTokenPayload()));
      }

      return null;
    } catch (err) {
      throw new Error('Unable to decode user payload');
    }
  }

  static async refreshAccessToken() {
    if (!this.getAccessTokenPayload())
      return Promise.reject(new Error('No user info found in local storage!'));

    try {
      const {
        data: { access },
      } = await HTTP.post(URL.tokenRefresh);
      this.setAccessTokenPayload(access);
      return Promise.resolve(true);
    } catch (e) {
      return Promise.reject(e);
    }
  }

  static login(accessToken) {
    return HTTP.post(URL.login, {
      okta_access_token: accessToken,
    })
      .then((result) => {
        const token = result.data.access;

        // If status code is 20x, store the payload portion of the
        // returned JWT in LocalStorage
        try {
          this.setAccessTokenPayload(token);
        } catch (err) {
          return Promise.reject(new Error('Unauthorized login attempt!'));
        }

        // and return a resolved promise
        return Promise.resolve();
      })
      .catch((err) => {
        try {
          // return the server response if it exists
          const { detail, message } = err.response.data;
          return Promise.reject(message || detail);
        } catch (e) {
          return Promise.reject(
            new Error('Unable to login, please try again!')
          );
        }
      });
  }

  static async processOktaLogin() {
    const oktaToken = this.getOktaTokenFromLocalStorage();
    await this.login(oktaToken);
  }

  static getOktaTokenFromLocalStorage() {
    const token = localStorage.getItem('okta-token-storage');
    if (token) {
      return JSON.parse(token).accessToken.accessToken;
    }
    return '';
  }

  static async logout() {
    try {
      // clear out access token from memory
      this.accessTokenPayload = null;

      removeAllFromCacheExceptStartsWith(['boostRetail']);

      // have the API clear out our HttpOnly cookies
      await HTTP.post(URL.logout);

      // clear the users abilities
      clearAbility();

      return true;
    } catch (err) {
      return false;
    }
  }

  static isAuthenticated() {
    if (!this.getAccessTokenPayload()) return false;

    const currentUser = this.getCurrentUser();
    if (currentUser) {
      updateAbilityByUser(currentUser);
    }

    return true;
  }

  static getCurrentUser = () => {
    try {
      const currentUser = this.getDecodedAccessTokenPayload();
      if (currentUser) {
        const {
          user_id: id,
          name: fullName,
          picture: avatar,
          user_groups: groups,
          is_staff: isStaff,
          email,
        } = currentUser;

        return new User({
          id,
          fullName,
          email,
          avatar,
          groups,
          isStaff,
        });
      }

      return null;
    } catch (err) {
      return null;
    }
  };
}
