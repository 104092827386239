import { Chip, Box, Checkbox, Divider, FormControlLabel } from '@mui/material';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useDeepCompareEffect from 'use-deep-compare-effect';
import { statusAttributesMapping } from '../../components/JobTable/constants';
import { updateSelectedFilterItems } from '../../state/ducks/filters/actions';
import { fetchFilterItems } from '../../state/ducks/filters/operations';
import { jobsActions } from '../../state/ducks/jobs';

const sectionKey = 'callToAction';
const callToActionEndpoint = '/jobs/grouped-by/call-to-action';

const callToActionRequest = {
  sectionKey,
  searchQuery: '',
  apiSearchKey: '',
  determineSearchParams: null,
  endpoint: callToActionEndpoint,
  params: {},
  parseData: (items) =>
    new Map(items.map((item) => [item.call_to_action, item.count])),
};

function CallToActionBar() {
  const {
    [sectionKey]: callToActionSelectedFilterItems,
    ...otherSelectedFilterItems
  } = useSelector((state) => state.filters.selectedFilterItems);

  const callToActionFilterItems = useSelector(
    (state) => state.filters.filterItems.callToAction
  );
  const dispatch = useDispatch();

  useDeepCompareEffect(() => {
    dispatch(fetchFilterItems(callToActionRequest));
  }, [otherSelectedFilterItems]);

  const handleChange = (checked, callToActionChecked) => {
    if (!checked) {
      const removedFilter = new Map(
        Array.from(callToActionSelectedFilterItems).reduce(
          (accumulator, callToActionItem) =>
            callToActionItem[0] === callToActionChecked
              ? accumulator
              : [...accumulator, callToActionItem],
          []
        )
      );
      dispatch(jobsActions.changeCurrentPage(0));
      dispatch(updateSelectedFilterItems({ callToAction: removedFilter }));
      return;
    }
    dispatch(jobsActions.changeCurrentPage(0));
    dispatch(
      updateSelectedFilterItems({
        callToAction: new Map([
          ...callToActionSelectedFilterItems,
          [callToActionChecked, callToActionChecked],
        ]),
      })
    );
  };

  const callToActionItems = Array.from(callToActionFilterItems).map(
    ([key, value]) => ({
      name: key,
      count: value,
    })
  );

  return (
    <Box
      display="flex"
      alignItems="center"
      sx={{ paddingTop: '6px', paddingBottom: '4px', backgroundColor: 'white' }}
    >
      {callToActionItems.map((callToActionItem, index) => {
        const isNotLast = index !== callToActionItems.length - 1;
        const { label, color } = statusAttributesMapping[callToActionItem.name];
        const isChecked = Array.from(
          callToActionSelectedFilterItems.keys()
        ).includes(callToActionItem.name);
        return (
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            key={label}
            sx={{ marginLeft: '16px' }}
          >
            <FormControlLabel
              label={label}
              control={
                <Checkbox
                  checked={isChecked}
                  size="small"
                  id={`${label}-checkbox`}
                  onChange={(event) =>
                    handleChange(event.target.checked, callToActionItem.name)
                  }
                />
              }
              sx={{ marginRight: '8px' }}
            />
            <Chip
              label={
                callToActionItem.count > 999 ? '999+' : callToActionItem.count
              }
              id={`call_to_action_${label}`}
              variant="filled"
              size="small"
              color={color}
              sx={{ color: 'white', marginRight: '16px' }}
            />
            {isNotLast && (
              <Divider orientation="vertical" sx={{ height: '26px' }} />
            )}
          </Box>
        );
      })}
    </Box>
  );
}

export default CallToActionBar;
