import axios from 'axios';

import { getApiDomain } from '../config';
import createRequestHeadersInterceptor from './interceptors/requestHeadersInterceptor';
import createVersionUpdateInterceptor from './interceptors/versionUpdateInterceptor';
import createAuthRefreshInterceptor from './interceptors/authRefreshInterceptor';
import createCsrfTokenInterceptor from './interceptors/csrfTokenInterceptor';
import createSafariTrailingSlashInterceptorFix from './interceptors/safariTrailingSlashInterceptorFix';

const HTTP = axios.create({
  baseURL: getApiDomain(process.env.REACT_APP_ENV_BUILD),
  timeout: 60000, // TODO: Move this back to something more reasonable ... a whole minute is crazy stuff!
  withCredentials: true,
});

createRequestHeadersInterceptor(HTTP);
createSafariTrailingSlashInterceptorFix(HTTP);
createCsrfTokenInterceptor(HTTP);
createAuthRefreshInterceptor(HTTP);
createVersionUpdateInterceptor(HTTP);

export default HTTP;
