import { combineReducers } from 'redux';
import * as types from './types';
import { DEFAULT_SORT_ORDERING } from './constants';

const jobsListReducer = (
  // eslint-disable-next-line default-param-last
  state = {
    results: [],
    numberOfRecords: 0,
    page: 0,
    indexLookup: {},
    isFetching: false,
    errorCode: null,
  },
  action
) => {
  switch (action.type) {
    case types.GET_JOBS_LIST_REQUEST:
      return Object.assign({}, state, {
        indexLookup: {},
        isFetching: true,
        errorCode: null,
      });
    case types.GET_JOBS_LIST_SUCCESS:
      return Object.assign({}, state, {
        results: action.payload.results || [],
        numberOfRecords: action.payload.count || 0,
        indexLookup: action.payload.results.reduce(
          (obj, job, i) => ({
            [job.id]: i,
            ...obj,
          }),
          {}
        ),
        isFetching: false,
      });
    case types.GET_JOBS_LIST_FAILURE:
      return Object.assign({}, state, {
        results: [],
        numberOfRecords: 0,
        indexLookup: {},
        isFetching: false,
        errorCode: action.payload,
      });

    case types.CHANGE_CURRENT_PAGE:
      return Object.assign({}, state, {
        page: action.payload,
      });

    default:
      return state;
  }
};

const jobsSortReducer = (
  // eslint-disable-next-line default-param-last
  state = {
    str: DEFAULT_SORT_ORDERING,
  },
  action
) => {
  switch (action.type) {
    case types.CHANGE_SORT:
      return Object.assign({}, state, {
        str: action.payload || DEFAULT_SORT_ORDERING,
      });
    default:
      return state;
  }
};

export default combineReducers({
  list: jobsListReducer,
  sort: jobsSortReducer,
});
