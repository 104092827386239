export const EXCEPTIONS_TO_MATCH_DETAILS = {
  is_available: {
    exception_text: 'Unavailable',
    text: 'Available to work',
    showToggle: true,
  },
  within_commute_miles: {
    exception_text: 'Drive Limitations',
    text: 'Does not exceed preferred drive distance',
    showToggle: true,
  },
  is_certified: {
    exception_text: 'Missing Certifications',
    text: 'Has all required certifications',
    showToggle: true,
  },
  allow_overnight: {
    exception_text: 'Overnight Travel',
    text: 'Willing to travel and stay overnight at a hotel',
    showToggle: true,
  },
  previously_declined: {
    exception_text: 'Declined',
    text: 'Has not declined this assignment',
    showToggle: true,
  },
};

export const EXCEPTIONS_TO_MATCH = {
  ...EXCEPTIONS_TO_MATCH_DETAILS,
  has_remaining_hours: {
    exception_text: 'Not enough hours',
    text: 'Enough hours',
    showToggle: false,
  },
};
