import React from 'react';
import { DrawerErrorWrapper } from '../../../styles/components';

function ShowError({ msg, doRetry, fullHeight }) {
  return (
    <DrawerErrorWrapper fullHeight={fullHeight}>
      <p>{msg}</p>
      <button className="button" onClick={doRetry}>
        Retry
      </button>
    </DrawerErrorWrapper>
  );
}

ShowError.defaultProps = {
  fullHeight: false,
};

export default ShowError;
