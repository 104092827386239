import * as types from './types';

export const setSuggestAssociateJob = (job) => ({
  type: types.SET_SUGGEST_ASSOCIATE_JOB,
  payload: job,
});

export const setAdvancedSearch = (advancedSearch) => ({
  type: types.SET_ADVANCED_SEARCH,
  payload: advancedSearch,
});
