import React, { useState } from 'react';
import { Button } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';

import { addDays, format, subDays } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';
import {
  ArrowDownWrapper,
  ArrowsWrapper,
  BlackSpan,
  DateBolder,
  Day,
  DayButton,
  DaysButtonWrapper,
  MonthArrowWrapper,
  MonthDaySelectorWrapper,
  MonthSpan,
  MonthWrapper,
  StyledArrowDropDown,
} from '../style';

function MonthAndWeekdaySelector(props) {
  const { jobStartDate, handleDayChange, timezone } = props;
  const [isOpen, setIsOpen] = useState(false);
  const [selectedDay, setSelectedDay] = useState(
    utcToZonedTime(new Date(jobStartDate), timezone)
  );
  const [buttonVariant] = useState([
    'contained',
    'outlined',
    'outlined',
    'outlined',
    'outlined',
  ]);

  const handleDateChange = (date) => {
    setIsOpen(false);
    setSelectedDay(date);
    handleDayChange(date);
  };

  const field = ({ InputProps }) => (
    <ArrowDownWrapper style={{ display: 'inline' }}>
      <StyledArrowDropDown
        ref={InputProps.ref}
        onClick={() => setIsOpen(true)}
      />
    </ArrowDownWrapper>
  );
  return (
    <MonthDaySelectorWrapper>
      <MonthArrowWrapper>
        <MonthWrapper>
          <MonthSpan id="month-span">{format(selectedDay, 'MMMM')}</MonthSpan>
          <DatePicker
            open={isOpen}
            onOpen={() => setIsOpen(true)}
            onClose={() => setIsOpen(false)}
            onChange={(value) => handleDateChange(value)}
            format="MMMM"
            value={selectedDay}
            slotProps={{
              field: {
                InputProps: {
                  ref: null,
                },
              },
            }}
            slots={{
              field,
            }}
          />
        </MonthWrapper>
        <ArrowsWrapper>
          <Button
            id="back-five-days"
            onClick={() => {
              handleDateChange(subDays(new Date(selectedDay), 5));
            }}
          >
            <BlackSpan className="material-icons">arrow_back_ios</BlackSpan>
          </Button>
          <Button
            id="forward-five-days"
            onClick={() => {
              handleDateChange(addDays(new Date(selectedDay), 5));
            }}
          >
            <BlackSpan className="material-icons">arrow_forward_ios</BlackSpan>
          </Button>
        </ArrowsWrapper>
      </MonthArrowWrapper>
      <DaysButtonWrapper>
        {buttonVariant.map((variant, index) => {
          const newDate = addDays(new Date(selectedDay), index);
          const day = format(newDate, 'eee');
          const date = newDate.getDate();
          return (
            <DayButton
              key={date}
              id={`date-btn-${index}`}
              onClick={() => {
                handleDateChange(addDays(new Date(selectedDay), index));
              }}
              variant={variant}
            >
              <div>
                <Day>{day}</Day>
                <DateBolder variant={variant}>{date}</DateBolder>
              </div>
            </DayButton>
          );
        })}
      </DaysButtonWrapper>
    </MonthDaySelectorWrapper>
  );
}

export default MonthAndWeekdaySelector;
