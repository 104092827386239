import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AccordionDetails from '@mui/material/AccordionDetails';
import Grid from '@mui/material/Grid';
import React from 'react';
import Accordion from '@mui/material/Accordion';
import { Avatar } from '@mui/material';
import {
  AssociateDetail,
  AssociateName,
  NoAssociate,
  NoAssociateInfo,
  StyledAccordionSummary,
  StyledEmailLink,
  StyledPhoneNumber,
  SuggestButton,
  SuggestButtonWrapper,
} from '../style';
import { determineInitials } from '../../Menu/utils';

function AssociateCollapsableHeader({
  user,
  associateNameClick,
  suggestAssociateClick,
}) {
  return (
    <Accordion defaultExpanded>
      <StyledAccordionSummary expandIcon={<ExpandMoreIcon />}>
        Associate
      </StyledAccordionSummary>
      <AccordionDetails>
        <Grid container spacing={2}>
          {user ? (
            <>
              <Grid item xs={2}>
                <Avatar css={{ height: '112px', width: '112px' }}>
                  {determineInitials(user.full_name)}
                </Avatar>
              </Grid>
              <Grid item xs={10}>
                <AssociateName
                  underline="hover"
                  id="associate-full-name"
                  onClick={associateNameClick}
                >
                  {user.full_name}
                </AssociateName>
                <AssociateDetail id="associate-employee-id">
                  {user.external_id}
                </AssociateDetail>
                <AssociateDetail id="associate-title">
                  {user.title}
                </AssociateDetail>
                <AssociateDetail id="associate-address">
                  {user.profile.address}
                </AssociateDetail>
                <AssociateDetail id="associate-full-address">{`${user.profile.city}, ${user.profile.state} ${user.profile.postal_code}`}</AssociateDetail>
                <StyledEmailLink
                  href={`mailto:${user.email}`}
                  id="associate-email"
                >
                  {user.email}
                </StyledEmailLink>
                <StyledPhoneNumber id="associate-phone-number">
                  {user.phone_number || 'No phone number provided.'}
                </StyledPhoneNumber>
              </Grid>
            </>
          ) : (
            <Grid xs={12}>
              <NoAssociate id="no-associate">No Associates</NoAssociate>
              <NoAssociateInfo id="no-associate-info">
                Suggest associates by clicking the button below.
              </NoAssociateInfo>
              <SuggestButtonWrapper>
                <SuggestButton
                  id="details-suggest-btn"
                  variant="outlined"
                  onClick={suggestAssociateClick}
                >
                  Suggest Associate
                </SuggestButton>
              </SuggestButtonWrapper>
            </Grid>
          )}
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
}

AssociateCollapsableHeader.defaultProps = {
  user: null,
};

export default AssociateCollapsableHeader;
