import React from 'react';
import Drawer from '@mui/material/Drawer';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Divider, Slide } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import AssociateDetails from '../../components/AssociateDetails';
import { setViewAssociate } from '../../state/ducks/viewAssociate/actions';
import { DEFAULT_VIEW_ASSOCIATE } from '../../state/ducks/viewAssociate/constants';
import { setSuggestAssociateJob } from '../../state/ducks/suggestAssociate/actions';
import { DEFAULT_SUGGEST_ASSOCIATE_JOB } from '../../state/ducks/suggestAssociate/constants';
import SuggestAssociates from '../../components/SuggestAssociates';

function AssociateDrawer() {
  const selectedAssociate = useSelector((state) => state.viewAssociate);
  const suggestAssociateJob = useSelector(
    (state) => state.suggestAssociate.job
  );
  const openSuggestAssociates = !!suggestAssociateJob.id;
  const openAssociateDetails = !!selectedAssociate.user_id;
  const open = openSuggestAssociates || openAssociateDetails;
  const dispatch = useDispatch();
  const theme = useTheme();
  const onClose = () => {
    dispatch(setViewAssociate(DEFAULT_VIEW_ASSOCIATE));
    dispatch(setSuggestAssociateJob(DEFAULT_SUGGEST_ASSOCIATE_JOB));
  };

  function determineComponents() {
    if (openSuggestAssociates) {
      return (
        <Box css={{ display: 'flex' }}>
          <Slide
            direction="left"
            in={openAssociateDetails}
            mountOnEnter
            unmountOnExit
            timeout={115}
          >
            <Box>
              <AssociateDetails />
            </Box>
          </Slide>
          <Divider orientation="vertical" />
          <SuggestAssociates />
        </Box>
      );
    }
    if (openAssociateDetails) {
      return <AssociateDetails />;
    }
    return <Box css={{ width: '550px' }} />;
  }

  return (
    <Drawer
      anchor="right"
      open={open}
      onClose={onClose}
      css={{ zIndex: theme.zIndex.drawer + 2 }}
    >
      {determineComponents()}
    </Drawer>
  );
}

export default AssociateDrawer;
