import { useSelector } from 'react-redux';

function Feature({ flag, children }) {
  const featureFlag = useSelector((state) => state.core.featureFlag);

  const feature = featureFlag?.find((t) => t.name === flag);

  if (feature && feature.is_enabled) {
    return children;
  }

  return null;
}

export default Feature;
