import { Ability, AbilityBuilder } from '@casl/ability';

import { datadogLogs } from '@datadog/browser-logs';

/**
 * @name ability
 * This is the core of our permissions system
 *
 * By default we are not setting explicit permissions.  That happens when
 * either the user logs in or we find they are already authenticated.  We
 * then call 'updateAbilityByUser' with the user info
 *
 * Some documentatin links:
 *    https://medium.com/dailyjs/what-is-casl-or-how-can-you-build-a-castle-around-your-application-4d2daa0b1ab4
 *    https://medium.com/dailyjs/managing-user-permissions-in-your-react-app-a93a94ff9b40
 *    https://www.npmjs.com/package/@casl/ability
 *    https://www.npmjs.com/package/@casl/react
 *
 */

const ability = new Ability([]); // default to user having no explicit permissions
window.ability = ability; // for debug purposes if needed

export function createAbilityRulesByUser(user) {
  const { groups, isStaff } = user;

  if (!groups) return [];

  const { rules, can } = new AbilityBuilder(ability);
  const isAdmin =
    groups.includes('administrator') || groups.includes('central_staffer');
  const isBizOps = groups.includes('bizops');
  const isClient = groups.includes('client');

  const isStoreManager = groups.includes('store managers');
  const isJyver = groups.includes('jyver');

  // [BEGIN] GENERAL ACCESS RULES
  if (isAdmin || isStaff) {
    can('access', 'Central');
    can('access', 'Reviews');
    can('write', 'Reviews');
    can('access', 'Admin');
  }

  if (isBizOps) {
    can('access', 'Reviews');
    can('write', 'Reviews');
    can('access', 'Admin');
  }

  if (isClient) {
    can('access', 'Central');
  }
  // [END] GENERAL ACCESS RULES

  // [BEGIN] FILTER RULES
  if (isAdmin || isStaff || isBizOps) {
    can('filter', 'statuses');
    can('filter', 'divisions');
    can('filter', 'projectName');
    can('filter', 'projectId');
    can('filter', 'assignmentId');
    can('filter', 'bannerRetailer');
    can('filter', 'brands');
    can('filter', 'states');
    can('filter', 'zipCodes');
    can('filter', 'cityStates');
    can('filter', 'jobTypes');
    can('filter', 'homeSystem');
    can('filter', 'jyvers');
    can('filter', 'stores');
    can('filter', 'tdLinx');
  }

  if (isClient) {
    can('filter', 'statuses');
    can('filter', 'stores');
  }
  // [END] FILTER RULES

  // If the user is in the store manager or jyver group, we want to toss
  // them out right away. We also want to make sure that even if they
  // aren't in one of those two groups, if they at the same time aren't
  // in one of our accepted groups, we also toss them out
  // TODO: this should ultimately be blocked by the backend auth
  if (
    (isStoreManager || isJyver) &&
    !isAdmin &&
    !isStaff &&
    !isBizOps &&
    !isClient
  ) {
    const error = new Error('Unauthorized user!');
    datadogLogs.logger.error(
      'An unauthorized user tried to log in to Central',
      { user },
      error
    );
  }

  return rules;
}

/**
 * @name updateAbilityByUser
 * This is the core of what defines what the user has access to.
 * Currently based on their user groups info, but can easily be switched
 * to something more granular on the permissions level.
 */
export function updateAbilityByUser(user) {
  const rules = createAbilityRulesByUser(user);
  ability.update(rules);
}

export function clearAbility() {
  ability.update([]);
}

export default ability;
