import React, { useState } from 'react';
import styled from '@emotion/styled/macro';
import { format, utcToZonedTime } from 'date-fns-tz';

import { Tooltip } from '@mui/material';
import { HoverableDateTimeDiv, JobDateTimePickerWrapper } from '../style';
import JobDateTimePicker from './JobDateTimePicker';
import { convertMinsToHrsMins } from '../../../lib/utils';

const ExpectedDuration = styled.div`
  display: inline;
  border-radius: 2px;
`;

const disabledText = (isNotEditable, isCompleted) => {
  if (isNotEditable) {
    return 'The Job Source System does not allow Datetime editing';
  }
  if (isCompleted) {
    return 'Assignments in a Completed status cannot be edited';
  }
  return '';
};

function JobTimeInfo({
  id,
  status,
  startDate,
  endDate,
  createdJobStartAfter,
  createdJobFinishBefore,
  storeTimezone,
  eta,
  advantageSourceSystemName,
  externalIdentifier,
  getUpdatedJob,
}) {
  const isCompleted = status === 'Completed';
  const isNotEditable = advantageSourceSystemName !== 'JET';
  const disabled = isNotEditable || isCompleted;
  const [isHovering, setIsHovering] = useState(false);
  const startDateWithTimezone = utcToZonedTime(startDate, storeTimezone);
  const endDateWithTimezone = utcToZonedTime(endDate, storeTimezone);
  const timeZoneOptions = { timeZone: storeTimezone };

  const timeOut = (
    <>
      {format(startDateWithTimezone, 'h:mm a', timeZoneOptions)}
      {' - '}
      {format(endDateWithTimezone, 'h:mm a', timeZoneOptions)}{' '}
      <span>
        (
        {format(utcToZonedTime(startDate, storeTimezone), 'z', timeZoneOptions)}
        )
      </span>
    </>
  );

  return (
    <HoverableDateTimeDiv
      onMouseEnter={() => {
        setIsHovering(true);
      }}
      onMouseLeave={() => {
        setIsHovering(false);
      }}
      className="job-time-wrapper"
    >
      <Tooltip title={disabledText(isNotEditable, isCompleted)}>
        <section className="job-date-wrapper--time">{timeOut}</section>
        {eta && (
          <ExpectedDuration title={convertMinsToHrsMins(eta)}>
            {convertMinsToHrsMins(eta, false, true)}
          </ExpectedDuration>
        )}
      </Tooltip>
      {isHovering && !disabled && (
        <JobDateTimePickerWrapper>
          <JobDateTimePicker
            id={id}
            openTo="hours"
            value={startDateWithTimezone}
            minDateTime={utcToZonedTime(createdJobStartAfter, storeTimezone)}
            maxDateTime={utcToZonedTime(createdJobFinishBefore, storeTimezone)}
            advantageSourceSystemName={advantageSourceSystemName}
            externalIdentifier={externalIdentifier}
            getUpdatedJob={getUpdatedJob}
            storeTimezone={storeTimezone}
          />
        </JobDateTimePickerWrapper>
      )}
    </HoverableDateTimeDiv>
  );
}

JobTimeInfo.defaultProps = {
  eta: null,
};

export default JobTimeInfo;
