import React, { useEffect, useMemo, useState } from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { debounce } from '@mui/material/utils';

export default function AsyncAutocomplete({
  initialValue,
  onInputChange,
  noOptionsText,
  isActive,
  sx,
  onChange,
  label,
  disabled,
  renderOption,
}) {
  const [value, setValue] = useState(null);
  const [inputValue, setInputValue] = useState('');
  const [options, setOptions] = useState([]);
  const [disabledOnChange, setDisabledOnChange] = useState(false);
  const [loading, setLoading] = useState(false);

  const getResultsDebounce = useMemo(
    () =>
      debounce((debounceInputValue, callback) => {
        if (initialValue !== debounceInputValue) {
          onInputChange(debounceInputValue, callback);
        }
      }, 400),
    [initialValue, onInputChange]
  );

  useEffect(() => {
    let active = true;

    if (inputValue === '') {
      setOptions(value ? [value] : []);
      return undefined;
    }

    setLoading(true);

    getResultsDebounce(inputValue, (results) => {
      if (active) {
        let newOptions = [];

        if (value) {
          newOptions = [value];
        }

        if (results) {
          newOptions = [...newOptions, ...results];
        }

        setOptions(newOptions);
        setLoading(false);
      }
    });

    return () => {
      active = false;
    };
  }, [value, inputValue, getResultsDebounce]);

  return (
    <Autocomplete
      size="small"
      disableClearable
      sx={sx}
      filterOptions={(x) => x}
      disabled={disabled || disabledOnChange}
      loading={loading}
      options={options}
      autoComplete
      includeInputInList
      filterSelectedOptions
      value={value || initialValue}
      inputValue={inputValue}
      noOptionsText={inputValue.length >= 3 ? noOptionsText : ''}
      onChange={(event, newValue) => {
        setOptions(newValue ? [newValue, ...options] : options);
        setValue(newValue);
        if (newValue) {
          setDisabledOnChange(true);
          onChange(newValue, () => {
            setDisabledOnChange(false);
          });
        }
      }}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
      }}
      onOpen={() => isActive(true)}
      renderInput={(params) => <TextField {...params} label={label} />}
      renderOption={renderOption || null}
      onKeyDown={(event) => {
        event.stopPropagation();
      }}
    />
  );
}
