import * as types from './types';

const coreReducer = (
  // eslint-disable-next-line default-param-last
  state = {
    isMainMenuOpen: false,
    isMaintenanceModeActive: false,
    featureFlag: [],
  },
  action
) => {
  switch (action.type) {
    case types.SET_SHOW_MAIN_MENU:
      return {
        ...state,
        isMainMenuOpen: action.payload,
      };
    case types.ENABLE_MAINTENANCE_MODE:
      return {
        ...state,
        isMaintenanceModeActive: true,
      };
    case types.SET_FEATURE_FLAG:
      return {
        ...state,
        featureFlag: [...state.featureFlag, ...action.payload],
      };
    default:
      return state;
  }
};

export default coreReducer;
