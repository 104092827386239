export const selected = '#EBF6F9';
export const hovered = '#F5FBFC';
// Highlights
export const ink = '#02363D';
export const boostRetail = '#0B3B60';
export const watermelon = '#e54360';
export const secondaryColor = '#0090BA';
export const secondaryColorPartialTransparent = 'rgba(0, 144, 186, 0.12)';
export const secondaryColorHover = 'rgba(0, 144, 186, 0.1)';

export const match = '#4CAF50';

// Warning / Errors
export const warning = '#fff3cd';
export const error = '#f8d7da';
export const errorFontColor = '#E44D34';
export const nonMatchBadge = '#FF9B70';

// Tones of grey (plus white)
export const athensGrey = '#f6f7f9';
export const lightGrey = '#a9c3cc';
export const warmGrey = '#7a7a7a';
export const cloud = '#ededed';
export const offwhite = '#f8f8f8';
export const white = '#ffffff';
export const mercury = '#e7e7e7';
export const manatee = '#9A97A4';

// Text
export const primaryText = '#061a2ade';
export const secondaryText = '#757E87';
export const tertiaryText = '#A9C3CC';

// Links
export const link = '#3D8DBC';

export const completedColor = '#2DA280';

export const claimedColor = '#5B4C66';

export const pastDueColor = '#E3903D';

export const urgentColor = '#072943';

export const inProgressColor = '#3394C5';

// Job Statuses
export const statusesMap = {
  completed: completedColor,
  complete: completedColor,
  in_progress: inProgressColor,
  active: secondaryText,
  claimed: claimedColor,
  staffed: claimedColor,
  unclaimed: errorFontColor,
  unstaffed: errorFontColor,
  expired: primaryText,
  past_due: pastDueColor,
  canceled: errorFontColor,
  urgent: urgentColor,
};
