import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import isEmpty from 'lodash/isEmpty';
import {
  DateRangePicker,
  SingleInputDateRangeField,
} from '@mui/x-date-pickers-pro';
import { Box, Typography } from '@mui/material';
import { parse, format } from 'date-fns';
import { DATE_FNS_RANGE_FORMAT, DEFAULT_SELECTED_FILTERS } from '../constants';
import { jobsActions } from '../../../state/ducks/jobs';
import { filtersActions } from '../../../state/ducks/filters';
import NumberOfSelectedFiltersTag from './NumberOfSelectedFiltersTag';

function DatesSelector() {
  const dispatch = useDispatch();
  const selectedFilterItems = useSelector(
    (state) => state.filters.selectedFilterItems
  );
  const getValue = (key) => {
    const selectedDate = Array.from(
      (isEmpty(selectedFilterItems[key])
        ? DEFAULT_SELECTED_FILTERS[key]
        : selectedFilterItems[key]
      ).values()
    )[0];

    return parse(selectedDate, DATE_FNS_RANGE_FORMAT, new Date());
  };
  const determineDate = (key, date) =>
    date
      ? new Map([
          [
            format(date, DATE_FNS_RANGE_FORMAT),
            format(date, DATE_FNS_RANGE_FORMAT),
          ],
        ])
      : DEFAULT_SELECTED_FILTERS[key];

  const sectionKeys = ['activeRangeStart', 'activeRangeEnd'];
  return (
    <Box css={{ padding: '16px' }}>
      <Box css={{ display: 'flex' }}>
        <Typography variant="subtitle1" css={{ marginBottom: '8px' }}>
          Date Range
        </Typography>
        <NumberOfSelectedFiltersTag sectionKeys={sectionKeys} />
      </Box>
      <DateRangePicker
        slots={{ field: SingleInputDateRangeField }}
        value={[getValue('activeRangeStart'), getValue('activeRangeEnd')]}
        onChange={(dates) => {
          dispatch(jobsActions.changeCurrentPage(0));
          dispatch(
            filtersActions.updateSelectedFilterItems({
              activeRangeStart: determineDate('activeRangeStart', dates[0]),
              activeRangeEnd: determineDate('activeRangeEnd', dates[1]),
            })
          );
        }}
        slotProps={{ textField: { size: 'small' } }}
        css={{
          width: '100%',
        }}
      />
    </Box>
  );
}

export default DatesSelector;
