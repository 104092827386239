import styled from '@emotion/styled/macro';
import { Button } from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { BrightnessHigh, Verified } from '@mui/icons-material';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import { primaryText, secondaryColor, white } from '../../styles/theme/colors';
import {
  primaryFont,
  primaryFontLineHeight,
  primaryFontSize,
  primaryFontWeight,
  secondaryFont,
  secondaryFontLineHeight,
  secondaryFontSize,
  secondaryFontWeight,
} from '../../styles/theme/fonts';
import { tabletCutoff } from '../../styles/theme';

export const HeaderWrapper = styled.header`
  width: 550px;
  position: relative;
  color: ${primaryText};
  background-color: ${white};
  overflow: hidden;
  padding: 8px 16px;

  a:hover {
    text-decoration: underline;
  }

  &::after {
    content: ' ';
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 70px;
    background-image: linear-gradient(
      rgba(255, 255, 255, 0),
      rgba(255, 255, 255, 1)
    );
  }

  *:not(div) {
    /* In order for the blurred map overlay to not go over everything else */
    z-index: 10;
    position: relative;
  }

  .close-drawer {
    position: absolute;
    top: 15px;
    right: 15px;
    font-size: 24px;
    background-color: white;
    padding: 10px;
    border-radius: 50%;
    line-height: 0;
    cursor: pointer;
    z-index: 20;
    transition: all 0.1s ease-in-out;

    i {
      color: ${primaryText};
    }

    &:hover {
      box-shadow: 0 0 25px rgba(0, 0, 0, 0.15);
    }
  }

  .content-wrapper {
    display: inline-block;

    h1 {
      font-size: 1.4rem;
      font-weight: 200;
      margin: 0;

      strong {
        font-weight: 600;
      }
    }

    h2 {
      font-size: 1rem;
      margin: 0;
    }

    @media only screen and (max-width: ${tabletCutoff}) {
      h1 {
        font-size: 1.1em;
      }

      h2 {
        font-size: 1em;
      }

      .color-status-bar--text {
        font-size: 0.75rem;
      }
    }
  }
`;

export const RoundedBox = styled.div`
  border-radius: 10px;
  border: 1px solid #c6cace;
  padding: 10px;
  margin: 12px;
  background-color: white;
`;

export const RoundedBoxNoPadding = styled.div`
  border-radius: 10px;
  border: 1px solid #c6cace;
  margin: 8px 16px 16px 16px;
  background-color: white;
`;

export const RoundedFlexBox = styled.div`
  border-radius: 10px;
  border: 1px solid #c6cace;
  padding: 10px;
  margin: 12px;
  background-color: white;
  display: flex;
`;

export const StyledNoWork = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 10px;
  padding-bottom: 10px;
`;

export const MonthDaySelectorWrapper = styled.div`
  padding: 16px;
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: white;
  border-top: outset;
`;

export const MonthArrowWrapper = styled.div`
  display: flex;
  width: 100%;
`;

export const MonthWrapper = styled.div`
  width: 50%;
`;

export const ArrowsWrapper = styled.div`
  width: 50%;
  justify-content: flex-end;
  display: flex;
`;

export const DaysButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-evenly;
`;

export const DateBolder = styled.span`
  font-family: ${secondaryFont};
  font-weight: ${primaryFontWeight};
  font-size: ${secondaryFontSize};
  color: ${(props) => (props.variant === 'contained' ? white : '#061A2ADE')};
`;

export const TimeSpan = styled.span`
  font-family: ${primaryFont};
  font-weight: ${primaryFontWeight};
  font-size: ${secondaryFontSize};
`;

export const Day = styled.div`
  font-family: ${secondaryFont};
  font-weight: ${primaryFontWeight};
  font-size: ${secondaryFontSize};
`;

export const TimeDuration = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 4px 8px;

  background: rgba(11, 59, 96, 0.04);
  border-radius: 4px;
  width: fit-content;
  flex: none;
  order: 1;
  flex-grow: 0;
`;

export const MonthSpan = styled.span`
  margin-right: 15%;
  font-family: ${primaryFont};
  font-weight: ${primaryFontWeight};
  font-size: ${secondaryFontSize};
`;

export const BlackSpan = styled.span`
  color: black;
`;

export const SecondaryColorSpan = styled.span`
  color: ${secondaryColor};
`;

export const DayButton = styled(Button)`
  && {
    margin-top: 12px;
    color: ${(props) => (props.variant === 'contained' ? white : '#061A2A99')};
    background-color: ${(props) =>
      props.variant === 'contained' ? secondaryColor : white};
    border-color: #061a2a3b;
  }
`;

export const AssignmentDiv = styled.div`
  border-bottom: 1px solid #c6cace;
`;

export const PaddedDiv = styled.div`
  display: flex;
  padding: 10px;
  font-family: ${primaryFont};
  font-weight: ${primaryFontWeight};
  font-size: ${secondaryFontSize};
`;

export const LeftSpan = styled.span`
  width: 50%;
  font-family: ${secondaryFont};
  font-weight: ${secondaryFontWeight};
  font-size: ${secondaryFontSize};
`;

export const HomeStoreDiv = styled.div`
  padding-left: 10px;
  font-family: ${secondaryFont};
  font-weight: ${secondaryFontWeight};
  font-size: ${secondaryFontSize};
`;

export const HomeStoreInfoWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const HomeStoreAddressWrapper = styled.div`
  width: 50%;
`;

export const FirstHomeStoreDiv = styled(HomeStoreDiv)`
  padding-top: 16px;
`;

export const LastHomeStoreDiv = styled(HomeStoreDiv)`
  padding-bottom: 16px;
`;

export const RightSpan = styled.span`
  width: 50%;
  display: flex;
  justify-content: flex-end;
  font-family: ${secondaryFont};
  font-weight: ${secondaryFontWeight};
  font-size: ${secondaryFontSize};
`;

export const PaddedRightSpan = styled(RightSpan)`
  padding-right: 10px;
`;

export const ArrowDownWrapper = styled.div`
  display: inline;
`;

export const StyledArrowDropDown = styled(ArrowDropDownIcon)`
  && {
    margin-bottom: -8px;
  }
`;

export const StyledBrightnessHigh = styled(BrightnessHigh)`
  && {
    color: #479c6b;
    margin-bottom: 16px;
  }
`;

export const CertificationsInner = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 4px;
`;

export const StyledVerified = styled(Verified)`
  && {
    color: #479c6b;
  }
`;

export const Certification = styled.span`
  margin-left: 16px;
  margin-top: 8px;
  font-family: ${secondaryFont};
  font-size: ${secondaryFontSize};
  font-weight: ${secondaryFontWeight};
  line-height: ${secondaryFontLineHeight};
  text-align: left;
`;

export const NoCertifications = styled.div`
  font-family: ${primaryFont};
  font-size: ${primaryFontSize};
  font-weight: ${primaryFontWeight};
  line-height: ${primaryFontLineHeight};
  text-align: center;
`;

export const NoCertificationsDetails = styled.div`
  font-family: ${secondaryFont};
  font-size: ${secondaryFontSize};
  font-weight: ${secondaryFontWeight};
  line-height: ${secondaryFontLineHeight};
  text-align: center;
`;

export const BlackSquare = styled.span`
  display: inline-block;
  width: 5px;
  height: 5px;
  background-color: #061a2ade;
  margin-bottom: 2px;
`;

export const OvernightWork = styled.span`
  font-weight: bolder;
  color: red;
  margin-left: 10px;
`;

export const OvernightWorkWrapper = styled.div`
  display: inline-flex;
`;

export const RedErrorOutlineOutlinedIcon = styled(ErrorOutlineOutlinedIcon)`
  && {
    color: red;
  }
`;

export const AccordionHeader = styled.div`
  font-family: ${primaryFont};
  font-size: ${primaryFontSize};
  font-weight: ${primaryFontWeight};
  line-height: ${primaryFontLineHeight};
`;

export const BoldHeader = styled.strong`
  font-family: ${primaryFont};
  font-size: ${primaryFontSize};
  font-weight: ${primaryFontWeight};
  line-height: ${primaryFontLineHeight};
`;

export const AssociateDetails = styled.h6`
  font-family: ${secondaryFont};
  font-size: ${secondaryFontSize};
  font-weight: ${secondaryFontWeight};
  line-height: ${secondaryFontLineHeight};
`;

export const Assign = styled.span`
  font-family: ${secondaryFont};
  font-size: ${secondaryFontSize};
  font-weight: ${primaryFontWeight};
  line-height: 24px;
  color: ${secondaryColor};
`;

export const AssignButton = styled.button`
  border: 1px solid;
  border-radius: 4px;
  border-color: ${secondaryColor};
  width: inherit;
`;

export const SelectedDay = styled.div`
  font-family: ${secondaryFont};
  font-size: ${secondaryFontSize};
  font-weight: ${secondaryFontWeight};
  line-height: ${secondaryFontLineHeight};
`;

export const SecondaryFontSize = styled.div`
  font-size: ${secondaryFontSize};
`;

export const StyledDiv = styled.div`
  display: flex;
  justify-content: space-between;
`;
