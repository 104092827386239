/* eslint-disable camelcase */
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import useDeepCompareEffect from 'use-deep-compare-effect';
import isEmpty from 'lodash/isEmpty';
import { Box, Button, Stack } from '@mui/material';
import { isEqual } from 'lodash';

import {
  DrawerErrorWrapper,
  DrawerWrapper,
  LoadingWrapper,
} from '../../styles/components';
import Header from './components/Header';
import SecondaryHeader from './components/SecondaryHeader';
import { getMatchedAssociates, putAssociate } from '../../services/associates';
import Spinner from '../Spinner';
import Associate from './components/Associate';
import { jobsOperations } from '../../state/ducks/jobs';
import {
  AssociateName,
  CurrentlyAssignedViewButton,
  CurrentlyAssignedWrapper,
  ErrorCloseButton,
  FirstIntermediateHeaderWrapper,
  CurrentlyAssignedTo,
  NameAndIdWrapper,
  NotFoundItem,
  NotFoundItemPrimary,
  NotFoundItemSecondary,
  StyledAssociates,
  StyledEmpty,
  StyledPagination,
  AssociateId,
  Footer,
  StyledCount,
} from './style';
import { DEFAULT_VIEW_ASSOCIATE } from '../../state/ducks/viewAssociate/constants';
import { ASSOCIATE_ASSIGNED_SUCCESS_TEXT } from '../../containers/Home/constants';
import { formatErrorFromSubsystem } from '../../lib/utils';
import { setSuggestAssociateJob } from '../../state/ducks/suggestAssociate/actions';
import { setViewAssociate } from '../../state/ducks/viewAssociate/actions';
import {
  DEFAULT_ADVANCED_SEARCH,
  DEFAULT_SUGGEST_ASSOCIATE_JOB,
} from '../../state/ducks/suggestAssociate/constants';
import { setConfirmation } from '../../state/ducks/dialog/actions';
import { setToast } from '../../state/ducks/toast/actions';

function ShowError({ msg, doRetry, fullHeight }) {
  return (
    <DrawerErrorWrapper fullHeight={fullHeight}>
      <p>{msg}</p>
      <button className="button" onClick={doRetry}>
        Retry
      </button>
    </DrawerErrorWrapper>
  );
}

ShowError.defaultProps = {
  fullHeight: false,
};

let abortController = new AbortController();

function SuggestAssociates() {
  const [page, setPage] = useState(1);
  const [associates, setAssociates] = useState([]);
  const [associatesCount, setAssociatesCount] = useState(0);
  const [errorMessage, setErrorMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const lastAdvancedSearchOptions = useSelector(
    (state) => state.suggestAssociate.advancedSearch
  );
  const hasCachedAdvancedSearchOptions = !isEqual(
    lastAdvancedSearchOptions,
    DEFAULT_ADVANCED_SEARCH
  );
  const [isExpanded, setIsExpanded] = useState(hasCachedAdvancedSearchOptions);
  const [currentAdvancedSearchOptions, setCurrentAdvancedSearchOptions] =
    useState(lastAdvancedSearchOptions);
  const wrapperRef = useRef(React.createRef());
  const suggestAssociateJob = useSelector(
    ({ suggestAssociate: { job } }) => job
  );
  const dispatch = useDispatch();
  const jobId = suggestAssociateJob.id;
  const pageSize = 10;

  const loadMatchedAssociates = () => {
    if (jobId) {
      abortController.abort();
      abortController = new AbortController();
      const { signal } = abortController;

      setErrorMessage(null);
      setIsLoading(true);

      getMatchedAssociates(
        {
          jobId,
          page,
          pageSize,
          ...lastAdvancedSearchOptions,
        },
        signal
      )
        .then((data) => {
          setIsLoading(false);
          setAssociatesCount(data.count);
          setAssociates(data.results);
        })
        .catch((err) => {
          if (signal && !signal.aborted) {
            setErrorMessage(
              err.message || 'There was a problem retrieving the Job record'
            );
            setIsLoading(false);
          }
        });
    }
  };

  const closeDrawer = () => {
    dispatch(setViewAssociate(DEFAULT_VIEW_ASSOCIATE));
    dispatch(setSuggestAssociateJob(DEFAULT_SUGGEST_ASSOCIATE_JOB));
  };

  const wipeState = () => {
    setAssociates([]);
    setAssociatesCount(0);
    setErrorMessage(null);
    setIsLoading(true);
    setPage(1);
    dispatch(setViewAssociate(DEFAULT_VIEW_ASSOCIATE));
    dispatch(setSuggestAssociateJob(DEFAULT_SUGGEST_ASSOCIATE_JOB));
  };

  const handleShowAssociateDetails = (associate) => {
    dispatch(setViewAssociate(associate));
  };

  const handleAssignAssociateClick = (associateId, ignoreWarnings = false) => {
    setIsLoading(true);
    if (jobId) {
      putAssociate({ associateId, jobId, ignoreWarnings })
        .then(() => {
          dispatch(setToast({ message: ASSOCIATE_ASSIGNED_SUCCESS_TEXT }));
          closeDrawer();
          dispatch(jobsOperations.fetchJobs());
        })
        .catch(
          ({
            response: {
              data: { error_code, detail },
            },
          }) => {
            setIsLoading(false);
            if (error_code === 'ValidationWarning') {
              dispatch(
                setConfirmation({
                  dialog: {
                    title: 'Warning',
                    text: detail,
                  },
                  button: {
                    onClick: () => {
                      handleAssignAssociateClick(associateId, true);
                    },
                    text: 'Continue',
                  },
                })
              );
              return;
            }
            dispatch(
              setToast({
                message: formatErrorFromSubsystem(
                  'Error when assigning or un-assigning',
                  detail,
                  suggestAssociateJob.external_identifier,
                  suggestAssociateJob.advantage_source_system_name
                ),
                error: true,
              })
            );
          }
        );
    }
  };

  useEffect(() => {
    setPage(1);
    setIsExpanded(hasCachedAdvancedSearchOptions);
    setCurrentAdvancedSearchOptions(lastAdvancedSearchOptions);
  }, [suggestAssociateJob.id]);

  useDeepCompareEffect(() => {
    loadMatchedAssociates();
  }, [page, lastAdvancedSearchOptions]);

  if (errorMessage) {
    return (
      <Box css={{ width: '550px' }}>
        <ErrorCloseButton>
          <button className="close-drawer" onClick={closeDrawer}>
            <i className="material-icons">close</i>
          </button>
        </ErrorCloseButton>
        <ShowError
          msg={errorMessage}
          doRetry={loadMatchedAssociates}
          fullHeight
        />
      </Box>
    );
  }
  return (
    <DrawerWrapper
      id="suggest-associates"
      ref={wrapperRef}
      css={{ width: '550px' }}
    >
      <Header
        assignmentId={suggestAssociateJob.external_identifier}
        associatesPageCount={associates.length}
        associatesCount={associatesCount}
        setPage={setPage}
        lastAdvancedSearchOptions={lastAdvancedSearchOptions}
        currentAdvancedSearchOptions={currentAdvancedSearchOptions}
        setCurrentAdvancedSearchOptions={setCurrentAdvancedSearchOptions}
        loadMatchedAssociates={() => {
          setPage(1);
          loadMatchedAssociates();
        }}
        isExpanded={isExpanded}
        setIsExpanded={setIsExpanded}
        isLoading={isLoading}
        jobId={suggestAssociateJob.id}
        onClose={wipeState}
        homeSystem={suggestAssociateJob.advantage_source_system_name}
      />
      {suggestAssociateJob.user && (
        <CurrentlyAssignedWrapper>
          <FirstIntermediateHeaderWrapper>
            <NameAndIdWrapper>
              <CurrentlyAssignedTo>CURRENTLY ASSIGNED TO:</CurrentlyAssignedTo>
              <AssociateName id="currently_assigned_associate_name">
                {suggestAssociateJob.user.full_name}
              </AssociateName>
              <AssociateId id="currently_assigned_associate_id">
                {suggestAssociateJob.user.external_id}
              </AssociateId>
            </NameAndIdWrapper>
            <CurrentlyAssignedViewButton>
              <Button
                id="currently_assigned_associate_view_btn"
                sx={{ color: '#0090BA', borderColor: '#0090BA' }}
                variant="outlined"
                onClick={() =>
                  handleShowAssociateDetails({
                    user_id: suggestAssociateJob.user.id,
                    hideMatchDetails: true,
                    hideAssignButton: true,
                    ...suggestAssociateJob.user,
                  })
                }
              >
                View
              </Button>
            </CurrentlyAssignedViewButton>
          </FirstIntermediateHeaderWrapper>
        </CurrentlyAssignedWrapper>
      )}
      <SecondaryHeader />
      {!isLoading ? (
        <StyledAssociates id="matched-associates">
          {!isEmpty(associates) ? (
            associates.map((associate) => (
              <Associate
                key={associate.user_id}
                associate={associate}
                storeLocationId={suggestAssociateJob.store_location_id}
                onShowAssociateDetails={handleShowAssociateDetails}
                handleAssignAssociateClick={handleAssignAssociateClick}
              />
            ))
          ) : (
            <Stack>
              <NotFoundItem>
                <StyledEmpty data-testid="empty-matched-associates" />
              </NotFoundItem>
              <NotFoundItemPrimary>No Matched Associates</NotFoundItemPrimary>
              <NotFoundItemSecondary>
                The advanced search will allow you to change the search to find
                matches.
              </NotFoundItemSecondary>
            </Stack>
          )}
        </StyledAssociates>
      ) : (
        <LoadingWrapper>
          <Spinner height="60" width="60" color="rgba(0, 0, 0, 0.1)" />
        </LoadingWrapper>
      )}
      {associatesCount > 0 && (
        <Footer>
          <StyledCount id="total_count">
            {`${(page - 1) * pageSize + 1} - ${Math.min(
              page * pageSize,
              associatesCount
            )} of ${associatesCount}`}
          </StyledCount>
          <StyledPagination
            count={Math.ceil(associatesCount / pageSize)}
            page={page}
            onChange={(event, value) => {
              setPage(value);
            }}
          />
        </Footer>
      )}
    </DrawerWrapper>
  );
}

export default SuggestAssociates;
