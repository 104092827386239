import React from 'react';

import { Button, Typography } from '@mui/material';
import ErrorPage from '../../components/ErrorPage';
import { ReactComponent as AccessDeniedIcon } from '../../assets/empty-states/403-error.svg';

function Unauthorized() {
  return (
    <ErrorPage icon={AccessDeniedIcon}>
      <Typography variant="h6">Access Denied</Typography>
      <Typography variant="body1">
        You do not have access to this page. You can submit a support ticket to
        request access below.
      </Typography>
      <Button
        id="request-access-button"
        variant="outlined"
        sx={{
          margin: '16px 6px',
        }}
        href="https://helpdesk.asmnet.com/hc/en-us/articles/17336867416468-Request-for-updates-to-permissions"
      >
        Request Access
      </Button>
    </ErrorPage>
  );
}

export default Unauthorized;
