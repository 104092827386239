/* eslint-disable lines-between-class-members */

export default class User {
  id;
  fullName;
  email;
  avatar;
  groups;
  isStaff;

  constructor(info) {
    const { id, fullName, email, avatar, groups, isStaff } = info;

    this.id = id;
    this.fullName = fullName;
    this.email = email;
    this.avatar = avatar;
    this.groups = groups;
    this.isStaff = isStaff || false;
  }
}
