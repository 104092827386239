/* eslint-disable camelcase */
import React from 'react';
import classNames from 'classnames';

import TextField from '@mui/material/TextField';
import { useDispatch } from 'react-redux';
import {
  AssignmentIdAndHomeSystem,
  HeaderWrapper,
  MatchedAssociatesHeader,
  SecondaryFontBolder,
  StyledAdvancedSearchH2,
  StyledAdvancedSearchWrapper,
  StyledCountSuggestWrapper,
  StyledOuter,
  ToggleLabel,
} from '../style';
import ToggleSwitch from '../../ToggleSwitch';
import { EXCEPTIONS_TO_MATCH_DETAILS } from '../constants';
import { snakeToCamel } from '../../../services/case';
import { removeNonNumeric } from '../../../services/text';
import { setAdvancedSearch } from '../../../state/ducks/suggestAssociate/actions';

const buildToggle = (label, id, checked, setChecked) => (
  <div key={label}>
    <ToggleSwitch
      id={`${id}_switch`}
      checked={checked}
      onToggle={(isChecked) => {
        setChecked(isChecked);
      }}
    />
    <ToggleLabel>{label}</ToggleLabel>
  </div>
);

function Header({
  assignmentId,
  setPage,
  homeSystem,
  isExpanded,
  setIsExpanded,
  isLoading,
  onClose,
  lastAdvancedSearchOptions,
  currentAdvancedSearchOptions,
  setCurrentAdvancedSearchOptions,
}) {
  const handleExpandToggle = () => {
    setIsExpanded(!isExpanded);
  };

  const dispatch = useDispatch();
  const { milesRadius } = currentAdvancedSearchOptions;
  const hasSearchChanged = Object.entries(currentAdvancedSearchOptions).every(
    ([key, value]) => lastAdvancedSearchOptions[key] === value
  );
  const isErrored = milesRadius === '';

  return (
    <HeaderWrapper data-testid="matched-associates-header">
      <button
        id="suggest-associate-drawer-close-button"
        className="close-drawer"
        onClick={onClose}
      >
        <i className="material-icons">close</i>
      </button>
      <div className="content-wrapper">
        <MatchedAssociatesHeader variant="h6">
          Matched Associates
        </MatchedAssociatesHeader>
        <AssignmentIdAndHomeSystem id="assignment_id_and_home_system">
          FOR ASSIGNMENT ID {assignmentId} {homeSystem}
        </AssignmentIdAndHomeSystem>
        <StyledOuter
          className={[classNames({ 'is-expanded': isExpanded })].join(' ')}
        >
          <StyledAdvancedSearchWrapper
            onClick={handleExpandToggle}
            tabIndex={0}
            id="advancedSearch-toggle-btn"
          >
            <StyledAdvancedSearchH2>Advanced Search</StyledAdvancedSearchH2>
            <i className="material-icons expand-icon">expand_more</i>
          </StyledAdvancedSearchWrapper>
          {isExpanded && (
            <>
              {Object.keys(EXCEPTIONS_TO_MATCH_DETAILS).map((exception) => {
                const exceptionCamelCase = snakeToCamel(exception);
                return buildToggle(
                  EXCEPTIONS_TO_MATCH_DETAILS[exception].text,
                  exception,
                  currentAdvancedSearchOptions[exceptionCamelCase],
                  (value) => {
                    setCurrentAdvancedSearchOptions({
                      ...currentAdvancedSearchOptions,
                      [exceptionCamelCase]: value,
                    });
                  }
                );
              })}
              <TextField
                id="search_radius"
                label="Search radius (miles)"
                type="text"
                size="small"
                value={milesRadius}
                onChange={({ target: { value } }) => {
                  setCurrentAdvancedSearchOptions({
                    ...currentAdvancedSearchOptions,
                    milesRadius: removeNonNumeric(value),
                  });
                }}
                error={isErrored}
                helperText={isErrored ? 'Search radius number is required' : ''}
                sx={{
                  marginTop: '15px',
                }}
              />
            </>
          )}
        </StyledOuter>
        <StyledCountSuggestWrapper>
          {isExpanded && (
            <button
              type="submit"
              data-testid="suggest-btn"
              className="applyBtn button is-link"
              disabled={isLoading || hasSearchChanged || isErrored}
              onClick={() => {
                setPage(1);
                dispatch(setAdvancedSearch(currentAdvancedSearchOptions));
              }}
            >
              <SecondaryFontBolder>Suggest</SecondaryFontBolder>
            </button>
          )}
        </StyledCountSuggestWrapper>
      </div>
    </HeaderWrapper>
  );
}

export default Header;
