import React, { useEffect } from 'react';
import { Navigate } from 'react-router-dom';

import { useDispatch, useSelector } from 'react-redux';
import { fetchFlagsStatus } from 'state/ducks/core/operations';
import auth from '../../services/auth';
import { VIEWS } from '../config';
import Unauthorized from '../../containers/Unauthorized';

function AuthorizationWrapper({ component: Component, ...rest }) {
  const dispatch = useDispatch();
  const isAuthenticated = auth.isAuthenticated();
  const isAuthorized = useSelector((state) => state.access.isAuthorized);

  useEffect(() => {
    if (isAuthenticated) dispatch(fetchFlagsStatus(['showTabs']));
  }, []);

  if (isAuthenticated && isAuthorized) {
    return <Component {...rest} />;
  }

  if (!isAuthorized) {
    return <Unauthorized />;
  }

  return (
    <Navigate
      to={{
        pathname: VIEWS.logout,
        state: { from: rest.location },
      }}
    />
  );
}

export default AuthorizationWrapper;
