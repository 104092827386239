import React, { PureComponent } from 'react';
import styled from '@emotion/styled/macro';

import { GeneralTag } from '../../../styles/components';
import { statusesMap, white } from '../../../styles/theme/colors';
import { capitalizeFirstLetter } from '../../../lib/utils';

const JobStatusTagText = styled(GeneralTag)`
  display: inline-block;
  background-color: ${(props) =>
    statusesMap[props.status.replace(' ', '_').toLowerCase()]};

  &:hover {
    color: ${white};
    text-decoration: none !important;
    opacity: 0.8;
  }
`;

export default class JobStatusTag extends PureComponent {
  static defaultProps = {
    reviewStatus: null,
  };

  render() {
    const { reviewStatus, status } = this.props;

    return (
      <JobStatusTagText
        as="span"
        update={{ sts: status }}
        className={`color-status-bar--text ${status}`}
        status={status}
        reviewStatus={reviewStatus}
      >
        {capitalizeFirstLetter(status)}
      </JobStatusTagText>
    );
  }
}
