import React, { PureComponent } from 'react';
import styled from '@emotion/styled/macro';
import { keyframes } from '@emotion/react/macro';

const rotate = keyframes`
  100% {
    transform: rotate(360deg);
  }
`;

const dash = keyframes`
  0% {
    stroke-dasharray: 1, 150;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -35;
  }
  100% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -124;
  }
`;

const SpinnerWrapper = styled.svg`
  animation: ${rotate} 2s linear infinite;
  display: block;

  & .path {
    stroke-linecap: round;
    animation: ${dash} 1.5s ease-in-out infinite;
  }
`;

export default class Spinner extends PureComponent {
  static defaultProps = {
    color: 'rgba(0, 0, 0, 0.5)',
    height: '17px',
    width: '17px',
  };

  render() {
    const { color, height, width } = this.props;

    return (
      <SpinnerWrapper viewBox="0 0 50 50" style={{ height, width }}>
        <circle
          className="path"
          cx="25"
          cy="25"
          r="20"
          fill="none"
          strokeWidth="5"
          style={{ stroke: color }}
        />
      </SpinnerWrapper>
    );
  }
}
