import { DEFAULT_TOAST } from './constants';
import * as types from './types';

// eslint-disable-next-line default-param-last
const toastReducer = (state = DEFAULT_TOAST, { type, payload }) => {
  switch (type) {
    case types.SET_TOAST: {
      return payload;
    }
    default:
      return state;
  }
};

export default toastReducer;
