import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ClearFiltersCounter from './ClearFiltersCounter';
import { determineNumberOfFilters } from '../utils';
import { filtersActions } from '../../../state/ducks/filters';
import { DEFAULT_FILTER_QUERIES, DEFAULT_SELECTED_FILTERS } from '../constants';

function NumberOfSelectedFiltersTag({ sectionKeys }) {
  const selectedFilterItems = useSelector(
    (state) => state.filters.selectedFilterItems
  );
  const sectionSelectedFilters = Object.entries(selectedFilterItems).reduce(
    (accumulator, [key, value]) =>
      sectionKeys.includes(key)
        ? {
            ...accumulator,
            [key]: value,
          }
        : accumulator,
    {}
  );
  const numberOfSelectedFilters = determineNumberOfFilters(
    sectionSelectedFilters
  );
  const dispatch = useDispatch();

  if (numberOfSelectedFilters > 0) {
    return (
      <ClearFiltersCounter
        sectionKeys={sectionKeys}
        numberOfActiveFilters={numberOfSelectedFilters}
        onClear={() => {
          dispatch(
            filtersActions.updateSelectedFilterItems(
              sectionKeys.reduce(
                (accumulator, sectionKey) => ({
                  ...accumulator,
                  [sectionKey]: DEFAULT_SELECTED_FILTERS[sectionKey],
                }),
                {}
              )
            )
          );
          dispatch(
            filtersActions.updateFilterQueries(
              sectionKeys.reduce(
                (accumulator, sectionKey) => ({
                  ...accumulator,
                  [sectionKey]: DEFAULT_FILTER_QUERIES[sectionKey],
                }),
                {}
              )
            )
          );
        }}
      />
    );
  }

  return null;
}

export default NumberOfSelectedFiltersTag;
