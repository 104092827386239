import React from 'react';
import Drawer from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AccordionDetails from '@mui/material/AccordionDetails';
import Grid from '@mui/material/Grid';
import { format, utcToZonedTime } from 'date-fns-tz';
import { useDispatch } from 'react-redux';
import Accordion from '@mui/material/Accordion';
import { useTheme } from '@mui/material/styles';
import { convertMinsToHrsMins } from '../../lib/utils';
import { Header } from './components';
import {
  StyledAccordionSummary,
  StyledGridLabel,
  StyledGridValue,
} from './style';
import AssociateCollapsableHeader from './components/AssociateCollapsableHeader';
import AttachmentsCollapsableHeader from './components/AttachmentsCollapsableHeader';
import CertificationsCollapsableHeader from './components/CertificationsCollapsableHeader';
import { standardDatetimeFnsFormat } from '../../services/date';
import { setSuggestAssociateJob } from '../../state/ducks/suggestAssociate/actions';
import { setViewAssociate } from '../../state/ducks/viewAssociate/actions';

function JobDetailsDrawer({ job, open, onClose }) {
  const dispatch = useDispatch();
  const theme = useTheme();

  const suggestAssociateClick = () => {
    dispatch(setSuggestAssociateJob(job));
    onClose();
  };

  const associateNameClick = () => {
    const associateDetails = {
      user_id: job.user.id,
      fromJobDetails: true,
      ...job.user,
    };
    dispatch(setViewAssociate(associateDetails));
    dispatch(
      setSuggestAssociateJob({
        job_start_after: job.job_start_after,
        store_location_timezone: job.store_location_timezone,
      })
    );
    onClose();
  };

  const convertTimezoneAndFormat = (date) =>
    date
      ? format(
          utcToZonedTime(new Date(date), job.store_location_timezone),
          standardDatetimeFnsFormat,
          {
            timeZone: job.store_location_timezone,
          }
        )
      : '';
  const fields = {
    'Scheduled Start': convertTimezoneAndFormat(job.job_start_after),
    'Scheduled Finish': convertTimezoneAndFormat(job.job_finish_before),
    'Earliest Start By': convertTimezoneAndFormat(job.created_job_start_after),
    'Latest Finish By': convertTimezoneAndFormat(job.created_job_finish_before),
    Duration: convertMinsToHrsMins(job.estimated_time),
    'Work Type': `${job.advantage_type_of_work}`,
    Division: `${job.division} ${job.department}`,
    'Division Category': job.division_category,
    'Home System': job.advantage_source_system_name,
    'Project / Assignment ID': `${job.external_project_identifier} / ${job.external_identifier}`,
    'Brand / Client': job.client_name,
  };
  return (
    <Drawer
      anchor="right"
      open={open}
      onClose={onClose}
      css={{ zIndex: theme.zIndex.drawer + 2 }}
    >
      <Box
        className="job-details-drawer"
        css={{ width: 814, overflow: 'scroll' }}
      >
        <Header job={job} onClose={onClose} />
        <Accordion defaultExpanded>
          <StyledAccordionSummary expandIcon={<ExpandMoreIcon />}>
            Details
          </StyledAccordionSummary>
          <AccordionDetails>
            <Grid container spacing={2}>
              {Object.entries(fields).reduce(
                (accumulator, [label, value]) => [
                  ...accumulator,
                  <StyledGridLabel item xs={3} key={String(label)}>
                    {label}
                  </StyledGridLabel>,
                  <StyledGridValue item xs={3} key={String(value)}>
                    {value}
                  </StyledGridValue>,
                ],
                []
              )}
            </Grid>
          </AccordionDetails>
        </Accordion>
        <AssociateCollapsableHeader
          user={job.user}
          associateNameClick={associateNameClick}
          suggestAssociateClick={suggestAssociateClick}
        />
        <CertificationsCollapsableHeader
          requiredCertifications={job.credential_requirements}
        />
        <AttachmentsCollapsableHeader attachmentsUrl={job.attachments_url} />
      </Box>
    </Drawer>
  );
}

export default JobDetailsDrawer;
