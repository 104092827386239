import { isEqual } from 'lodash';
import { DEFAULT_SELECTED_FILTERS } from '../../../containers/Filters';

const convertFiltersMapToArrays = (updatedFilters) =>
  Object.entries(updatedFilters).reduce(
    (accumulator, [key, value]) => ({
      ...accumulator,
      [key]: Array.from(value),
    }),
    {}
  );

const removeDefaultsFilters = (filters) =>
  Object.entries(filters).reduce(
    (accumulator, [key, value]) => ({
      ...accumulator,
      [key]: isEqual(value, DEFAULT_SELECTED_FILTERS[key]) ? new Map() : value,
    }),
    {}
  );

export const mapFiltersForCache = (filters) =>
  convertFiltersMapToArrays(removeDefaultsFilters(filters));
