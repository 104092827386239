/* OPERATIONS = REDUX THUNKS */

import HTTP from 'core/http';
import { URL } from '../../../core/config';
import { setFeatureFlags } from './actions';

export const fetchFlagsStatus = (flagNames) => async (dispatch) => {
  try {
    const queryString = flagNames
      .map((flagName) => `flag_name=${encodeURIComponent(flagName)}`)
      .join('&');

    const response = await HTTP.get(`${URL.featureFlagStatus}?${queryString}`);
    const { data } = response;

    dispatch(setFeatureFlags(data));
  } catch (error) {
    throw new Error('Unable to get feature flags status');
  }
};
