import { v4 } from 'uuid';
import {
  ENV_E2ETESTS,
  ENV_E2ETESTS_CI,
  ENV_LOCAL,
  ENV_SHIPYARD_EPHEMERAL,
} from '../../config';

export default function createRequestHeadersInterceptor(axiosInstance) {
  const defaultVersionNumber = '99999999';
  return axiosInstance.interceptors.request.use((config) => ({
    ...config,
    requestStartTime: Date.now(),
    headers: {
      ...config.headers,
      'X-Jyve-Application-ID': 'Central',
      'X-Jyve-Platform-ID': 'Web',
      'X-Jyve-Client-Request-ID': v4(),
      'X-Jyve-Application-Version': `1.${String(
        [
          ENV_LOCAL,
          ENV_E2ETESTS,
          ENV_E2ETESTS_CI,
          ENV_SHIPYARD_EPHEMERAL,
        ].includes(process.env.REACT_APP_ENV_BUILD)
          ? defaultVersionNumber
          : process.env.REACT_APP_VERSION
      )}`,
    },
  }));
}
